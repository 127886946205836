import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MagazineRoutingModule} from './magazine-routing.module';
import {MagazineComponent} from './magazine.component';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreSidebarModule} from '../../../@core/components';
import {AddMagazineComponent} from './add-magazine/add-magazine.component';
import {ReactiveFormsModule} from '@angular/forms';


@NgModule({
    declarations: [MagazineComponent, AddMagazineComponent],
    exports: [
        AddMagazineComponent
    ],
    imports: [
        CommonModule,
        MagazineRoutingModule,
        NgbPaginationModule,
        CoreSidebarModule,
        ReactiveFormsModule,
    ]
})
export class MagazineModule {
}
