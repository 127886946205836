import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetaRoutingModule } from './meta-routing.module';
import { MetaComponent } from './meta.component';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreSidebarModule} from '../../../@core/components';
import { AddMetaComponent } from './add-meta/add-meta.component';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [MetaComponent, AddMetaComponent],
    exports: [ AddMetaComponent],
    imports: [
        CommonModule,
        MetaRoutingModule,
        NgbPaginationModule,
        CoreSidebarModule,
        ReactiveFormsModule,
    ]
})
export class MetaModule { }
