import { Injectable } from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {UploadService} from './upload.service';
import {Observable} from 'rxjs';
import {AppUrl} from '../constants/app-url';

@Injectable({
  providedIn: 'root'
})
export class AwardsService extends BaseService {

  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService,
              private fileUploadService: UploadService) {
    super(http, alertService, globalService);
  }


  get(data): Observable<any> {
    return this.getRequest(AppUrl.AWARDS(), data);
  }

  create(data): Observable<any> {
    return this.postRequest(AppUrl.AWARDS(), data);
  }

  delete(id): Observable<any> {
    return this.deleteRequest(AppUrl.AWARDS(id));
  }
  update(id, data): Observable<any> {
    return this.putRequest(AppUrl.AWARDS(id), data);
  }
  fileUploadAdmin(params, event): Observable<any> {
    return this.fileUploadService.fileUpload(AppUrl.FILE_UPLOAD_ADMIN(), params, event);
  }
}
