import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import 'hammerjs';
import {TranslateModule} from '@ngx-translate/core';
import {ToastrModule} from 'ngx-toastr'; // For auth after login toast
import {CoreModule} from '@core/core.module';
import {CoreCommonModule} from '@core/common.module';
// @ts-ignore
import {CoreSidebarModule, CoreThemeCustomizerModule} from '@core/components';
import {coreConfig} from 'app/app-config';
import {AppComponent} from 'app/app.component';
import {LayoutModule} from 'app/layout/layout.module';
import {SampleModule} from 'app/main/sample/sample.module';
import {DashboardModule} from './main/dashboard/dashboard.module';
import {SharedModule} from './shared/shared.module';
import {SnotifyModule, SnotifyService, ToastDefaults} from 'ng-snotify';
import {GlobalService, MomentService} from './shared/services/global.service';
import {AlertService} from './shared/services/alert.service';
import {HtpInterceptor} from './shared/services/interceptor.service';
import {AuthService} from './shared/auth/auth.service';
import {AuthGuard} from './shared/auth/auth-guard.service';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ImageCropperModule} from 'ngx-image-cropper';
import {DataSharing} from './shared/constants/DataSharing';
import {QuillModule} from 'ngx-quill';
import {BlogCategoriesModule} from './main/blog-categories/blog-categories.module';
import {AdminsComponent} from './main/admins/admins.component';
import {AddAdminComponent} from './main/admins/add-admin/add-admin.component';
import {AdminsModule} from './main/admins/admins.module';
import {ArticlesComponent} from './main/articles/articles.component';
import {ArticlesModule} from './main/articles/articles.module';
import {BlogsComponent} from './main/blogs/blogs.component';
import {BlogInfoComponent} from './main/blogs/blog-info/blog-info.component';
import {BlogsModule} from './main/blogs/blogs.module';
import {NewsVideosComponent} from './main/news-videos/news-videos.component';
import {NewsVideosModule} from './main/news-videos/news-videos.module';
import {NewsCategoriesComponent} from './main/news-categories/news-categories.component';
import {NewsCategoriesModule} from './main/news-categories/news-categories.module';
import {ArticleCategoriesComponent} from './main/article-categories/article-categories.component';
import {ArticleCategoriesModule} from './main/article-categories/article-categories.module';
import {AuthorComponent} from './main/author/author.component';
import {AuthorModule} from './main/author/author.module';
import {BlogCategoriesComponent} from './main/blog-categories/blog-categories.component';
import {MetaModule} from './main/meta/meta.module';
import {MagazineModule} from './main/magazine/magazine.module';
import {NewsPapersModule} from './main/news-papers/news-papers.module';
import {NgxTagsInputModule} from 'ngx-tags-input';
import {BreakingNewsModule} from "./main/breaking-news/breaking-news.module";
import {WebpageBannerModule} from "./main/webpage-banner/webpage-banner.module";
import { AwardsComponent } from './main/awards/awards.component';
import {AwardsModule} from './main/awards/awards.module';

const appRoutes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('././main/pages/pages.module').then(m => m.PagesModule)
    },
    {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: '/auth/miscellaneous/error' // Error 404 - Page not found
    }
];

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    declarations: [
        AppComponent,
        AdminsComponent,
        AddAdminComponent,
        ArticlesComponent,
        BlogsComponent,
        BlogInfoComponent,
        NewsVideosComponent,
        NewsCategoriesComponent,
        ArticleCategoriesComponent,
        AuthorComponent,
        BlogCategoriesComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {
            scrollPositionRestoration: 'enabled', // Add options right here
            relativeLinkResolution: 'legacy'
        }),
        TranslateModule.forRoot(),
        // NgBootstrap
        ToastrModule.forRoot(),
        // Core modules
        CoreModule.forRoot(coreConfig),
        QuillModule.forRoot(),
        CoreCommonModule,
        CoreSidebarModule,
        CoreThemeCustomizerModule,
        SharedModule,
        // App modules
        LayoutModule,
        SampleModule,
        SnotifyModule,
        DashboardModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        PerfectScrollbarModule,
        ImageCropperModule,
        BlogCategoriesModule,
        AdminsModule,
        ArticlesModule,
        BlogsModule,
        NewsVideosModule,
        NewsCategoriesModule,
        ArticleCategoriesModule,
        AuthorModule,
        MetaModule,
        MagazineModule,
        NewsPapersModule,
        NgxTagsInputModule,
        BreakingNewsModule,
        WebpageBannerModule,
        AwardsModule
    ],
    bootstrap: [AppComponent],
    providers: [
        AuthService,
        AuthGuard,
        HtpInterceptor,
        GlobalService,
        AlertService,
        {provide: 'SnotifyToastConfig', useValue: ToastDefaults},
        SnotifyService,
        MomentService,
        DataSharing,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ],
})
export class AppModule {
}
