import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MagazineComponent} from './magazine.component';
import {AddMagazineComponent} from './add-magazine/add-magazine.component';

const routes: Routes = [
    {
        path: 'magazine',
        component: MagazineComponent,
        data: {animation: 'magazines'}
    },
    {
        path: 'magazine/add',
        component: AddMagazineComponent,
        data: {animation: 'magazine/add'}
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MagazineRoutingModule {
}
