import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlogsRoutingModule } from './blogs-routing.module';
import { AddBlogComponent } from './add-blog/add-blog.component';
import {ReactiveFormsModule} from '@angular/forms';
import {BlogsComponent} from './blogs.component';
import {AddNewsCategoryComponent} from '../news-categories/add-news-category/add-news-category.component';
import {AddAuthorComponent} from '../author/add-author/add-author.component';


@NgModule({
  declarations: [ AddBlogComponent],
  exports: [
    AddBlogComponent
  ],
  imports: [
    CommonModule,
    BlogsRoutingModule,
    ReactiveFormsModule
  ],
})
export class BlogsModule { }
