import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {CoreSidebarService} from "../../../@core/components/core-sidebar/core-sidebar.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertService} from "../../shared/services/alert.service";
import {DomSanitizer} from "@angular/platform-browser";
import {ImageCroppedEvent} from "ngx-image-cropper";
import {NewVideosService} from "../../shared/services/new-videos.service";

@Component({
    selector: 'app-news-videos',
    templateUrl: './news-videos.component.html',
    styleUrls: ['./news-videos.component.scss']
})
export class NewsVideosComponent implements OnInit {

    showSearch = false;
    id: any;
    data: any;
    submitted: any = false;
    submitting: any = false;
    searchForm: FormGroup;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    onAirVideos = [];
    newsVideos = [];
    modalType: any;
    imageChangedIconEvent: any;
    croppedIconImageEvent: any;
    croppedIconImage: any;
    videoData: any;
    videoUrl: any;

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private newsVideoService: NewVideosService,
                private alertService: AlertService,
                public sanitizer: DomSanitizer) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('app-add-news-videos').toggleOpen();
    }

    add(): void {
        this.data = undefined;
        this.toggleSidebar();
    }

    refresh() {
        this.searchForm = this.fb.group({
            'heading': ['']
        });
        this.get();
    }

    getData(valueSubmit: any): void {
        if (valueSubmit) {
            this.get({page: 1, total: null, perpage: 15, pages: null});
            this.toggleSidebar();
        }
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.newsVideos = [];
        this.newsVideoService.get(params).subscribe(data => {
            if (data && data['data'] && data['data'].length > 0) {
                this.newsVideos = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.newsVideos = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    search() {
        const params = {
            'heading': (this.searchForm.value && this.searchForm.value.heading) ? this.searchForm.value.heading : ''
        };
        this.get(params);
    }

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'heading': (this.searchForm && this.searchForm.value && this.searchForm.value.heading) ? this.searchForm.value.heading : ''
        };
        this.get(params);
    }

    edit(data) {
        this.data = data;
        this.toggleSidebar();
    }

    Role

    delete(id) {
        this.newsVideoService.delete(id).subscribe(data => {
            this.get();
        });
    }

    openOnAirViewVideoModal(content, data) {
        this.videoData = data;
        this.modalType = 'ON_NEWS_VIDEO';
        if (this.videoData && this.videoData['frame_url']) {
            this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoData['frame_url']);
        }
        this.modalService.open(content, {size: 'lg'});

    }

    openOnAirIcon(data) {
        window.open(data['news_video_cover']['url'], '_blank');
    }

    openOnAirIconModal(content, id) {
        this.id = id;
        this.modalType = 'NEWS_VIDEO_ICON';
        this.modalService.open(content, {size: 'lg'});
    }

    fileChangeIconEvent(event: any): void {
        this.imageChangedIconEvent = event;
    }

    imageIconCropped(event: ImageCroppedEvent) {
        this.croppedIconImageEvent = event;
        this.croppedIconImage = event.base64;
    }

    imageLoaded() {
        // show cropper
    }

    cropperReady() {
        // cropper ready
    }

    loadImageFailed() {
        // show message
    }

    saveOnAirIcon() {
        const params = {
            'type': 'NEWS_VIDEO_COVER',
            'news_video_id': this.id,
            'fileType': 'BASE64'
        };
        this.newsVideoService.fileUploadAdmin(params, this.croppedIconImage).subscribe(data => {
            if (data) {
                this.imageChangedIconEvent = undefined;
                this.modalService.dismissAll('');
                this.alertService.showSuccess({'title': 'success', 'message': 'Image successfully loaded'});
                this.get();
            } else {
                this.imageChangedIconEvent = undefined;
                this.modalService.dismissAll('');
                this.alertService.showErrors({'title': 'failed', 'message': 'Image format not supported..'});
            }
        });
    }

    updateStatus(status, data) {
        this.submitted = true;
        if (this.submitted) {
            const params = {
                'status': status
            };
            this.submitting = true;
            this.newsVideoService.updateStatus(data.id, params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
                this.get();
            }, error => {
                this.submitting = false;
                this.submitted = false;
                this.id = undefined;
            });
        }
    }

}
