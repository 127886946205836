import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../../shared/services/alert.service';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../../../shared/services/global.service';
import {WebpageBannerService} from '../../../shared/services/webpage-banner.service';
import {AwardsService} from '../../../shared/services/awards.service';
import {AppConstants} from '../../../shared/constants/app-constant';

@Component({
  selector: 'app-add-awards',
  templateUrl: './add-awards.component.html',
  styleUrls: ['./add-awards.component.scss']
})
export class AddAwardsComponent  implements OnChanges {
  AllAwardsType: any = AppConstants.AllAwardsType;
  addAwardForm: FormGroup;
  id: any;
  submitted: any = false;
  submitting: any = false;
  insights: any = [];
  allInsight: any = [];
  @Output() valueSubmitted = new EventEmitter<any>();
  @Input() data: any;
  constructor(private _coreSidebarService: CoreSidebarService,
              private fb: FormBuilder,
              private alertService: AlertService,
              public http: HttpClient,
              public globalService: GlobalService,
              private awardService: AwardsService) { }

  ngOnChanges(): void {
    this.refresh();
  }

  toggleSidebar(): void {
    this._coreSidebarService.getSidebarRegistry('app-add-awards').toggleOpen();
  }
  refresh() {
    this.addAwardForm = this.fb.group({
      'awards_type': ['', (!this.id) ? Validators.required : ''],
      'years': ['', (!this.id) ? Validators.required : '']
    });
    if (this.data && this.data['id']) {
      this.patchForm();
    }
  }
  patchForm() {
    if (this.data) {
      this.getInsight(this.data['id']);
    }
    this.addAwardForm.patchValue({
      'awards_type': (this.data && this.data['awards_type']) ? this.data['awards_type'] : '',
      'years': (this.data && this.data['years']) ? this.data['years'] : '',
    });
  }

  getInsight(id) {
    this.insights = [];
    this.awardService.get(id).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.allInsight = (data && data['data']) ? data['data'] : [];
      } else {
        this.allInsight = [];
      }
    });
  }


  submit() {
    this.submitted = true;
    if (!this.addAwardForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'awards_type': (this.addAwardForm.value && this.addAwardForm.value.awards_type) ? this.addAwardForm.value.awards_type : '',
        'years': (this.addAwardForm.value && this.addAwardForm.value.years) ? this.addAwardForm.value.years : '',
      };
      this.submitting = true;
      this.awardService.create(params).subscribe(data => {
        if (data) {
          this.submitting = false;
          this.submitted = false;
          this.valueSubmitted.emit(true);
          this.addAwardForm.reset();
          this.addAwardForm.patchValue({
            'webpage': ''
          });
        }
      }, error => {
        this.submitting = false;
        this.submitted = false;
        if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
          if (error['error']['errors'].webpage && error['error']['errors'].webpage.length > 0) {
            this.alertService.showErrors(error['error']['errors'].webpage[0]);
            return;
          }
        }
      });
    }
  }

  update() {
    this.submitted = true;
    if (!this.addAwardForm.valid) {
      return;
    }
    if (this.submitted) {
      const params = {
        'awards_type': (this.addAwardForm.value && this.addAwardForm.value.awards_type) ? this.addAwardForm.value.awards_type : '',
        'years': (this.addAwardForm.value && this.addAwardForm.value.years) ? this.addAwardForm.value.years : '',
      };
      this.submitting = true;
      this.awardService.update(this.data['id'], params).subscribe(data => {
        this.submitting = false;
        this.submitted = false;
        this.valueSubmitted.emit(true);
        this.addAwardForm.reset();
        this.addAwardForm.patchValue({
          'awards_type': '',
          'years': ''
        });
      }, error => {
        this.submitting = false;
        this.submitted = false;
        if (error['error']['errors'].webpage && error['error']['errors'].webpage.length > 0) {
          this.alertService.showErrors(error['error']['errors'].webpage[0]);
          return;
        }
      });
    }
  }

}
