import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../../../shared/services/global.service';
import {MagazineService} from '../../../shared/services/magazine.service';

@Component({
    selector: 'app-add-magazine',
    templateUrl: './add-magazine.component.html',
    styleUrls: ['./add-magazine.component.scss']
})
export class AddMagazineComponent implements OnInit {
    addMagazineForm: FormGroup;
    id: any;
    submitted: any = false;
    submitting: any = false;
    onFileChanged: any;
    fileCourseCover: any;
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;
    selectedMagazine: any;
    authorList: any;
    articleCategories: any = [];
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                public http: HttpClient,
                public globalService: GlobalService,
                private magazineServices: MagazineService) {
    }

    ngOnInit(): void {

    }

    ngOnChanges(): void {
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-magazine').toggleOpen();
    }

    refresh() {
        this.addMagazineForm = this.fb.group({
            'title': ['', (!this.id) ? Validators.required : ''],
        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
    }

    patchForm() {
        // if (this.data) {
        //     this.getMagazine(this.data['id']);
        // }
        this.addMagazineForm.patchValue({
            'title': (this.data && this.data['title']) ? this.data['title'] : '',
        });
    }

    // getMagazine(id) {
    //     this.selectedMagazine = {};
    //     this.magazineServices.detail(id).subscribe(data => {
    //         this.selectedMagazine = data;
    //     });
    // }

    submit() {
        this.submitted = true;
        if (!this.addMagazineForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'title': (this.addMagazineForm.value && this.addMagazineForm.value.title) ? this.addMagazineForm.value.title : '',
            };
            this.submitting = true;
            this.magazineServices.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addMagazineForm.reset();
                    this.addMagazineForm.patchValue({
                        'title': '',
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].title && error['error']['errors'].title.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].title[0]);
                        return;
                    }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addMagazineForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'title': (this.addMagazineForm.value && this.addMagazineForm.value.title) ? this.addMagazineForm.value.title : '',
            };
            this.submitting = true;
            this.magazineServices.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
                this.addMagazineForm.reset();
                this.addMagazineForm.patchValue({
                    'title': '',
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].title && error['error']['errors'].title.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].title[0]);
                        return;
                    }
                }
            });
        }
    }

    // onFileChange(e, type, index?) {
    //   this.onFileChanged = e;
    //   this.magazineServices.fileUpload({'type': type, 'fileType': 'NORMAL'}, this.onFileChanged).subscribe(data => {
    //     if (type === 'ARTICLE_COVER') {
    //       this.fileCourseCover = data['data'];
    //     }
    //     this.alertService.showSuccess({title: 'Success', message: 'Uploaded Successfully'});
    //   }, error => {
    //     this.alertService.showErrors('Something went wrong, please try again');
    //   });
    // }

    openInsightCover() {
        window.open(this.fileCourseCover['url'], '_blank');
    }
}
