import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NewsPapersRoutingModule } from './news-papers-routing.module';
import { NewsPapersComponent } from './news-papers.component';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreSidebarModule} from '../../../@core/components';
import {CoreCommonModule} from '../../../@core/common.module';
import { AddNewsComponent } from './add-news/add-news.component';
import {MagazineModule} from '../magazine/magazine.module';


@NgModule({
  declarations: [NewsPapersComponent, AddNewsComponent],
  exports:[
      AddNewsComponent
  ],
    imports: [
        CommonModule,
        NewsPapersRoutingModule,
        NgbPaginationModule,
        CoreSidebarModule,
        CoreCommonModule,
        MagazineModule,
    ]
})
export class NewsPapersModule { }
