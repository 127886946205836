<div class="slideout-content">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog">
            <form class="add-new-user modal-content pt-0">
                <button
                        (click)="toggleSidebar()"
                        aria-label="Close"
                        class="close"
                        data-dismiss="modal"
                        type="button"
                >
                    ×
                </button>
                <div class="modal-header">
                    <h5 *ngIf="!data || !data['id']" class="modal-title">Add Award</h5>
                    <h5 *ngIf="data && data['id']" class="modal-title">Update Award</h5>
                </div>
                <div class="modal-body flex-grow-1">
                    <form [formGroup]="addAwardForm" class="form mt-2 shadow-none" novalidate>
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <fieldset class="form-group">
                                        <select class="form-control" formControlName="awards_type" id="awards_type">
                                            <option value="">Select Award Type</option>
                                            <option *ngFor="let awardType of AllAwardsType"
                                                    [value]="awardType?.value">{{awardType?.name}}</option>
                                        </select>
                                        <small *ngIf="submitted && addAwardForm.get('awards_type').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </fieldset>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="years">Years</label>
                                        <input class="form-control" formControlName="years" id="years" type="text">
                                        <small *ngIf="submitted && addAwardForm.get('years').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <button (click)="toggleSidebar()" aria-label="Close" class="btn btn-danger"
                                            data-dismiss="modal"
                                            rippleEffect type="button">
                                        Cancel
                                    </button>
                                    <button (click)="submit()" *ngIf="!data || !data['id']"
                                            class="btn btn-primary ml-1 pull-right"
                                            rippleEffect type="submit">Submit
                                    </button>
                                    <button (click)="update()" *ngIf="data && data['id']"
                                            class="btn btn-primary ml-1 pull-right"
                                            rippleEffect type="submit">Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </form>
        </div>
    </div>
</div>

