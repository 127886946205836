import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from "../../../../@core/components/core-sidebar/core-sidebar.service";
import {AlertService} from "../../../shared/services/alert.service";
import {HttpClient} from "@angular/common/http";
import {GlobalService} from "../../../shared/services/global.service";
import {WebpageBannerService} from "../../../shared/services/webpage-banner.service";
import {AppConstants} from "../../../shared/constants/app-constant";

@Component({
    selector: 'app-add-webpage',
    templateUrl: './add-webpage.component.html',
    styleUrls: ['./add-webpage.component.scss']
})
export class AddWebpageComponent implements OnChanges {
    AllWebpages: any = AppConstants.AllWebpages;
    addWebpageForm: FormGroup;
    id: any;
    submitted: any = false;
    submitting: any = false;
    insights: any = [];
    allInsight: any = [];
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                public http: HttpClient,
                public globalService: GlobalService,
                private webpageBannerService: WebpageBannerService) {
    }

    ngOnChanges(): void {
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('app-add-webpage').toggleOpen();
    }

    refresh() {
        this.addWebpageForm = this.fb.group({
            'webpage': ['', (!this.id) ? Validators.required : ''],
        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
    }

    patchForm() {
        if (this.data) {
            this.getInsight(this.data['id']);
        }
        this.addWebpageForm.patchValue({
            'webpage': (this.data && this.data['webpage']) ? this.data['webpage'] : '',
        });
    }

    getInsight(id) {
        this.insights = [];
        this.webpageBannerService.get(id).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.allInsight = (data && data['data']) ? data['data'] : [];
            } else {
                this.allInsight = [];
            }
        });
    }


    submit() {
        this.submitted = true;
        if (!this.addWebpageForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'webpage': (this.addWebpageForm.value && this.addWebpageForm.value.webpage) ? this.addWebpageForm.value.webpage : '',
            };
            this.submitting = true;
            this.webpageBannerService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addWebpageForm.reset();
                    this.addWebpageForm.patchValue({
                        'webpage': ''
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].webpage && error['error']['errors'].webpage.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].webpage[0]);
                        return;
                    }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addWebpageForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'webpage': (this.addWebpageForm.value && this.addWebpageForm.value.webpage) ? this.addWebpageForm.value.webpage : '',
            };
            this.submitting = true;
            this.webpageBannerService.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
                this.addWebpageForm.reset();
                this.addWebpageForm.patchValue({
                    'webpage': ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error['error']['errors'].webpage && error['error']['errors'].webpage.length > 0) {
                    this.alertService.showErrors(error['error']['errors'].webpage[0]);
                    return;
                }
            });
        }
    }
}
