import {Injectable} from '@angular/core';
import {BaseService} from "./base.service";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "./alert.service";
import {GlobalService} from "./global.service";
import {Observable} from "rxjs";
import {AppUrl} from "../constants/app-url";
import {UploadService} from "./upload.service";
@Injectable({
    providedIn: 'root'
})
export class BreakingNewsService extends BaseService {
    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService,
                private fileUploadService: UploadService) {
        super(http, alertService, globalService);
    }

    get(data): Observable<any> {
        return this.getRequest(AppUrl.BREAKING_NEWS(), data);
    }

    detail(id): Observable<any> {
        return this.getRequest(AppUrl.BREAKING_NEWS(id));
    }

    update(id, data): Observable<any> {
        return this.putRequest(AppUrl.BREAKING_NEWS(id), data);
    }
}
