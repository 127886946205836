import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AwardsRoutingModule } from './awards-routing.module';
import { AddAwardsComponent } from './add-awards/add-awards.component';
import {ReactiveFormsModule} from '@angular/forms';
import {CoreSidebarModule} from '../../../@core/components';
import {AwardsComponent} from './awards.component';
import {WebpageBannerModule} from '../webpage-banner/webpage-banner.module';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [AwardsComponent, AddAwardsComponent],
    imports: [
        CommonModule,
        AwardsRoutingModule,
        ReactiveFormsModule,
        CoreSidebarModule,
        WebpageBannerModule,
        NgbPaginationModule,
    ]
})
export class AwardsModule { }
