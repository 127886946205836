<div class="content-wrapper">
    <div class="content-body">
        <section id="Testimonials">
            <ng-template #content>
                <div id="modal">
                    <div class="modal-header font-weight-bold">
                        <h4 class="modal-title font-weight-bold">Update Category</h4>
                        <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                (click)="modalService.dismissAll('')"
                        >
                            ×
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <form class="form" novalidate [formGroup]="updateCategoryForm">
                                    <div class="px-3 container">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="category" class="d-flex">
                                                        Category
                                                        <i class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer"></i>
                                                    </label>
                                                    <input class="form-control" id="category"
                                                           formControlName="name">
                                                    <small *ngIf="submitted && updateCategoryForm.get('name').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="alias" class="d-flex">
                                                        Alias
                                                        <i class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer"></i>
                                                    </label>
                                                    <input class="form-control" id="alias"
                                                           formControlName="alias">
                                                    <small *ngIf="submitted && updateCategoryForm.get('alias').hasError('required')"
                                                           class="text-danger">
                                                        Field is required.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-success color-white pull-right" [disabled]="submitting">
                            <i class="fas fa-pen-square mr-1" *ngIf="!submitting"></i>
                            <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Update
                        </button>
                    </div>
                </div>
            </ng-template>
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <h2 class="mt-5px">Blog-Category</h2>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <button class="btn btn-primary pull-right color-white color-white users-btn mr-2"
                            (click)="showSearch = !showSearch">
                        <i aria-hidden="true" class="fa fa-search mr-8px"></i>&nbsp;Search
                    </button>
                    <button class="btn btn-primary pull-right color-white color-white users-btn mr-2 cursor-pointer"
                            (click)="add()">
                        <i aria-hidden="true" class="fa fa-plus-circle mr-8px"></i>&nbsp;Add Category
                    </button>
                </div>
            </div>
            <div class="row search-card mt-3" *ngIf="showSearch">
                <div class="col-12">
                    <form class="form mt-2 mb--8px" novalidate [formGroup]="searchForm">
                        <div class="row">
                            <div class="col-md-5 col-lg-5 col-sm-5 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="name"
                                           placeholder="Name">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12 text-center">
                                <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                                        (click)="search()"
                                        [disabled]="submitting">
                                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Search
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list text-center">
                                        <thead>
                                        <tr>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Name</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Alias</h6>
                                            </th>
<!--                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Tags</h6>-->
<!--                                            </th>-->
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Banner</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="blogCategories && blogCategories.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let category of blogCategories; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{category?.name}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{category?.alias}}</h6></td>
<!--                                            <td class="text-center">-->
<!--                                                <span class="badge"-->
<!--                                                      style="margin-right: 0.25rem;background-color: #7367f0 !important"-->
<!--                                                      *ngFor="let tagName of category?.tags.split(',')">{{tagName}}</span>-->
<!--                                            </td>-->
                                            <td class="text-center">
                                                <div *ngIf="category && category['blog_category_banner']"
                                                     class="btn-group"
                                                     role="group">
                                                    <button (click)="bannerUpload.click()" class="btn btn-primary"
                                                            title="Upload Banner"
                                                            type="button">
                                                        <i class="fas fa-cloud-upload-alt"></i>
                                                    </button>
                                                    <button (click)="openInsightBanner(category)"
                                                            class="btn btn-primary" title="View Banner"
                                                            type="button">
                                                        <i class="fas fa-eye"></i>
                                                    </button>
                                                </div>
                                                <button (click)="bannerUpload.click()"
                                                        *ngIf="category && !category['blog_category_banner']"
                                                        class="btn btn-primary color-white"
                                                        title="Upload">
                                                    <i class="fas fa-cloud-upload-alt"></i>
                                                </button>
                                                <input #bannerUpload
                                                       (change)="onFileChange($event, 'BLOG_CATEGORY_BANNER', category)"
                                                       class="form-control-file display-hidden"
                                                       type="file">
                                            </td>
                                            <td class="text-center">
                                                <i aria-hidden="true"
                                                   class="fas fa-edit f_20 text-info cursor-pointer font-weight-light mt-1"
                                                   title="Edit" (click)="edit(category)"></i>

                                                <i class="fas fa-check-circle f_20 ml-5px text-success cursor-pointer"
                                                   title="Active"
                                                   *ngIf="category && category['status'] === 'IN_ACTIVE'"
                                                   (click)="updateStatus(category, 'ACTIVE')"></i>
                                                <i class="fas fa-times-circle f_20 ml-5px text-danger cursor-pointer"
                                                   title="In Active"
                                                   *ngIf="category && category['status'] === 'ACTIVE'"
                                                   (click)="updateStatus(category, 'IN_ACTIVE')"></i>
                                                <i aria-hidden="true"
                                                   class="fas fa-trash f_20 ml-5px text-danger cursor-pointer mt-1"
                                                   title="Delete" (click)="delete(category.id)"></i>
                                            </td>
                                            <!--<td class="text-center">
                                                <i aria-hidden="true"
                                                   class="fas fa-lock f_20 ml-1 text-danger cursor-pointer "
                                                   title="In Active Executive" *ngIf="blog-categories?.status === 'ACTIVE'"
                                                   (click)="updateStatus('INACTIVE', blog-categories)"></i>
                                                <i aria-hidden="true"
                                                   class="fas fa-lock-open ml-1 f_20 text-success cursor-pointer "
                                                   title="Active Executive" *ngIf="blog-categories?.status === 'INACTIVE'"
                                                   (click)="updateStatus('ACTIVE', blog-categories)"></i>
                                                <i aria-hidden="true"
                                                   class="fas fa-edit f_20 text-info cursor-pointer font-weight-light "
                                                   title="Edit" (click)="edit(blog-categories)"></i>
                                            </td>-->
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td colspan="7" class="text-center"><h6>No data to show</h6></td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 d-block d-md-none"
                 *ngIf="blogCategories && blogCategories.length > 0; else noDataToShowMobile">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card" *ngFor="let categories of blogCategories; let i = index">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-6">
                                    <h4
                                            class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        . {{categories?.name}}</h4>
                                </div>
                                <div class="col-6">
                                    <i aria-hidden="true"
                                       class="fas fa-lock f_20 ml-1 text-danger cursor-pointer pull-right"
                                       title="In Active Executive" *ngIf="categories?.status === 'INACTIVE'"
                                       (click)="updateStatus('ACTIVE', categories)"></i>
                                    <i aria-hidden="true"
                                       class="fas fa-lock-open ml-1 f_20 text-success cursor-pointer pull-right"
                                       title="Active Executive" *ngIf="categories?.status === 'ACTIVE'"
                                       (click)="updateStatus('INACTIVE', categories)"></i>
                                    <i aria-hidden="true"
                                       class="fas fa-edit f_20 text-info cursor-pointer font-weight-light pull-right"
                                       title="Edit" (click)="edit(categories)"></i>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-2">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    name - {{categories?.username}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Category - <span class="text-info">Update</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination class="pagination-primary" [collectionSize]="pagination.total"
                                [(page)]="pagination.page" [boundaryLinks]="true"
                                [pageSize]="pagination.perpage" [maxSize]="5"
                                (pageChange)="onPageChange($event)">
                    <ng-template ngbPaginationPrevious>
                           <span [data-feather]="'chevron-left'" [size]="18"
                                 [class]="'font-weight-bolder'">
                           </span>
                    </ng-template>
                    <ng-template ngbPaginationNext>
                           <span [data-feather]="'chevron-right'" [size]="18"
                                 [class]="'font-weight-bolder'">
                           </span>
                    </ng-template>
                </ngb-pagination>
            </div>
        </section>
    </div>
    <core-sidebar
            class="modal modal-slide-in sidebar-todo-modal fade"
            name="add-category"
            overlayClass="modal-backdrop">
        <app-add-category [data]="data" (valueSubmitted)="getData($event)"></app-add-category>
    </core-sidebar>
</div>


