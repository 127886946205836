import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NewsCategoriesRoutingModule} from './news-categories-routing.module';
import {AddNewsCategoryComponent} from './add-news-category/add-news-category.component';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
    declarations: [AddNewsCategoryComponent],
    imports: [
        CommonModule,
        NewsCategoriesRoutingModule,
        ReactiveFormsModule
    ],
    exports: [AddNewsCategoryComponent]
})
export class NewsCategoriesModule {
}
