<div class="content-wrapper">
    <div class="content-body">
        <section id="articles">
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <h2 class="mt-5px">Breaking News</h2>
                </div>
            </div>
            <!--            <pre class="text-white">{{breakingNews|json}}</pre>-->

            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Update Breaking News</h5>
                    <form [formGroup]="addBreakingNewsForm" class="form mt-2 shadow-none" novalidate>
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <textarea class="form-control resize-none" formControlName="news" id="news"
                                                  rows="6"></textarea>
                                        <small *ngIf="submitted && addBreakingNewsForm.get('news').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <button (click)="update()" class="btn btn-primary ml-1 pull-right"
                                            rippleEffect type="submit">Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </div>
</div>


