import {CoreMenu} from '@core/types';

export const menu: CoreMenu[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        translate: 'MENU.DASHBOARD',
        type: 'item',
        icon: 'fas fa-sign',
        url: 'dashboard',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        id: 'admin',
        title: 'Admins',
        translate: 'MENU.ADMINS',
        type: 'item',
        icon: 'fas fa-user-shield',
        url: 'admins',
        availableTo: ['SUPER_ADMIN']
    },
    {
        id: 'author',
        title: 'Author',
        translate: 'MENU.AUTHOR',
        type: 'item',
        icon: 'fas fa-id-card',
        url: 'author',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        id: 'breaking-news',
        title: 'Breaking-News',
        translate: 'MENU.BREAKINGNEWS',
        type: 'item',
        icon: 'fas fa-id-card',
        url: 'breaking-news',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        id: 'news-categories',
        title: 'News Categories',
        translate: 'MENU.NEWSCATEGORIES',
        type: 'item',
        icon: 'fas fa-list',
        url: 'news-categories',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        id: 'news-videos',
        title: 'News',
        translate: 'MENU.NEWSVIDEOS',
        type: 'item',
        icon: 'fas fa-video',
        url: 'news-videos',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        id: 'article-categories',
        title: 'Article Categories',
        translate: 'MENU.ARTICLECATEGORIES',
        type: 'item',
        icon: 'fa fa-address-card-o',
        url: 'article-categories',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        id: 'articles',
        title: 'Articles',
        translate: 'MENU.ARTICLES',
        type: 'item',
        icon: 'fas fa-newspaper',
        url: 'articles',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        id: 'blog-categories',
        title: 'Blog Categories',
        translate: 'MENU.BLOGCATEGORIES',
        type: 'item',
        icon: 'fas fa-sliders-h',/*<i class="fas fa-sliders-h"></i>*/
        url: 'blog-categories',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        id: 'blogs',
        title: 'Blogs',
        translate: 'MENU.BLOGS',
        type: 'item',
        icon: 'fas fa-blog',
        url: 'blogs',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        id: 'meta',
        title: 'Metas',
        translate: 'MENU.META',
        type: 'item',
        icon: 'fas fa-sliders-h',
        url: 'meta',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        id: 'webpage-banner',
        title: 'Webpage Banner',
        translate: 'MENU.WEBPAGEBANNER',
        type: 'item',
        icon: 'fas fa-list',
        url: 'webpage-banner',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        id: 'magazine',
        title: 'Magazine',
        translate: 'MENU.MAGAZINE',
        type: 'item',
        icon: 'fas fa-sliders-h',
        url: 'magazine',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        id: 'news-papers',
        title: 'News Papers',
        translate: 'MENU.NEW_PAPERS',
        type: 'item',
        icon: 'fas fa-sliders-h',
        url: 'news-papers',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },
    {
        id: 'Awards',
        title: 'Awards',
        translate: 'MENU.AWARDS',
        type: 'item',
        icon: 'fa fa-address-card-o',
        url: 'awards',
        availableTo: ['SUPER_ADMIN', 'ADMIN']
    },

];
