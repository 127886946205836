import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthorComponent} from './author.component';
import {AddAuthorComponent} from './add-author/add-author.component';
import {BlogInfoComponent} from '../blogs/blog-info/blog-info.component';
import {AddAuthorInfoComponent} from './add-author-info/add-author-info.component';

const routes: Routes = [
  {
    path: 'author',
    component: AuthorComponent,
    data: {animation : 'author'}
  },
  {
    path: 'author/add',
    component: AddAuthorComponent,
    data: {animation : 'author/add'}
  },
  {
    path: 'author-info/:id',
    component: AddAuthorInfoComponent,
    data: {animation : 'author-info'}
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthorRoutingModule { }
