import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../../../shared/services/global.service';
import {ArticleService} from '../../../shared/services/article.service';
import {ArticleCategoryService} from '../../../shared/services/article-category.service';
import {AuthorService} from '../../../shared/services/author.service';
@Component({
    selector: 'app-add-article',
    templateUrl: './add-article.component.html',
    styleUrls: ['./add-article.component.scss']
})
export class AddArticleComponent implements OnInit, OnChanges {
    addArticlesForm: FormGroup;
    id: any;
    submitted: any = false;
    submitting: any = false;
    onFileChanged: any;
    fileCourseCover: any;
    tags: string[] = [];
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;
    selectedArticle: any;
    authorList: any;
    articleCategories: any = [];
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                public http: HttpClient,
                public globalService: GlobalService,
                private articleService: ArticleService,
                private authorServices: AuthorService,
                private articleCategoryService: ArticleCategoryService) {
    }

    ngOnInit(): void {
        this.getArticleCategory();
        this.getAuthor();
    }

    ngOnChanges(): void {
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-article').toggleOpen();
    }

    refresh() {
        this.addArticlesForm = this.fb.group({
            'heading': ['', (!this.id) ? Validators.required : ''],
            'author_id': ['', (!this.id) ? Validators.required : ''],
            'short_description': ['', (!this.id) ? Validators.required : ''],
            'article_category_id': ['', (!this.id) ? Validators.required : ''],
            'tags': ['', (!this.id) ? Validators.required : '']
        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
        // this.getArticleCategory();
    }

    addTags() {
        let tag = this.addArticlesForm.value.tags;
        this.tags = tag.trim().split(' ');
    }

    // addTags(tag: string) {
    //     this.tags = tag.trim().split(' ');
    // }

    patchForm() {
        if (this.data) {
            this.getArticle(this.data['id']);
        }
        this.addArticlesForm.patchValue({
            // 'user_id':
            'author_id': (this.data && this.data['author_id']) ? this.data['author_id'] : '',
            'heading': (this.data && this.data['heading']) ? this.data['heading'] : '',
            'short_description': (this.data && this.data['short_description']) ? this.data['short_description'] : '',
            'article_category_id': (this.data && this.data['article_category_id']) ? this.data['article_category_id'] : '',
            'tags': (this.data && this.data['tags']) ? this.data['tags'].replace(/,/g, ' ') : ''
        });
    }

    getArticle(id) {
        this.selectedArticle = {};
        this.articleService.detail(id).subscribe(data => {
            this.selectedArticle = data;
        });
    }
    getAuthor() {
        this.authorServices.get('').subscribe(data => {
            this.authorList = data['data'];
        });
    }

    getArticleCategory(params: any = {}) {
        this.articleCategories = [];
        this.articleCategoryService.get({'page': -1, 'status': 'ACTIVE'}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.articleCategories = data['data'];
            } else {
                this.articleCategories = [];
            }
        });
    }

    submit() {
        this.submitted = true;
        if (!this.addArticlesForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'author_id': (this.addArticlesForm.value && this.addArticlesForm.value.author_id) ? this.addArticlesForm.value.author_id : '',
                'heading': (this.addArticlesForm.value && this.addArticlesForm.value.heading) ? this.addArticlesForm.value.heading : '',
                'short_description': (this.addArticlesForm.value && this.addArticlesForm.value.short_description) ? this.addArticlesForm.value.short_description : '',
                'article_category_id': (this.addArticlesForm.value && this.addArticlesForm.value.article_category_id) ? this.addArticlesForm.value.article_category_id : '',
                'tags': this.tags ? this.tags : '',
            };
            this.submitting = true;
            this.articleService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addArticlesForm.reset();
                    this.addArticlesForm.patchValue({
                        'heading': '',
                        'author_id': '',
                        'short_description': '',
                        'article_category_id': '',
                        'tags': ''
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].university_name && error['error']['errors'].heading.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].heading[0]);
                        return;
                    }
                    if (error['error']['errors'].about_course && error['error']['errors'].short_description.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].short_description[0]);
                        return;
                    }
                    if (error['error']['errors'].about_course && error['error']['errors'].article_category_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].article_category_id[0]);
                        return;
                    }
                    if (error['error']['errors'].tags) {
                        this.alertService.showErrors(error['error']['errors'].tags[0]);
                        return;
                    }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addArticlesForm.valid) {
            return;
        }
        this.addTags();
        if (this.submitted) {
            const params = {
                'author_id': (this.addArticlesForm.value && this.addArticlesForm.value.author_id) ? this.addArticlesForm.value.author_id : '',
                'article_category_id': (this.addArticlesForm.value && this.addArticlesForm.value.article_category_id) ? this.addArticlesForm.value.article_category_id : '',
                'heading': (this.addArticlesForm.value && this.addArticlesForm.value.heading) ? this.addArticlesForm.value.heading : '',
                'short_description': (this.addArticlesForm.value && this.addArticlesForm.value.short_description) ? this.addArticlesForm.value.short_description : '',
                'tags': this.tags ? this.tags : ''
            };
            this.submitting = true;
            this.articleService.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
                this.addArticlesForm.reset();
                this.addArticlesForm.patchValue({
                    'author_id': '',
                    'article_category_id': '',
                    'heading': '',
                    'short_description': '',
                    'tags': ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].author_id && error['error']['errors'].author_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].author_id[0]);
                        return;
                    }
                    if (error['error']['errors'].article_category_id && error['error']['errors'].article_category_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].article_category_id[0]);
                        return;
                    }
                    if (error['error']['errors'].heading && error['error']['errors'].heading.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].heading[0]);
                        return;
                    }
                    if (error['error']['errors'].short_description && error['error']['errors'].short_description.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].short_description[0]);
                        return;
                    }
                    if (error['error']['errors'].tags) {
                        this.alertService.showErrors(error['error']['errors'].tags[0]);
                        return;
                    }
                }
            });
        }
    }

    onFileChange(e, type, index?) {
        this.onFileChanged = e;
        this.articleService.fileUpload({'type': type, 'fileType': 'NORMAL'}, this.onFileChanged).subscribe(data => {
            if (type === 'ARTICLE_COVER') {
                this.fileCourseCover = data['data'];
            }
            this.alertService.showSuccess({title: 'Success', message: 'Uploaded Successfully'});
        }, error => {
            this.alertService.showErrors('Something went wrong, please try again');
        });
    }

    openInsightCover() {
        window.open(this.fileCourseCover['url'], '_blank');
    }
}
