import {Component, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {DomSanitizer} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {ArticleService} from "../../../shared/services/article.service";
import {BlogService} from "../../../shared/services/blog.service";

@Component({
    selector: 'app-blog-info',
    templateUrl: './blog-info.component.html',
    styleUrls: ['./blog-info.component.scss']
})
export class BlogInfoComponent implements OnInit {

    public data: any;
    public toggleMenu = false;
    private _unsubscribeAll: Subject<any>;
    submitting: any = false;
    submitted: any = false;
    contentData: any;
    public blogId: any;

    constructor(private sanitizer: DomSanitizer,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private blogInfoService: BlogService) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.blogId = this.activatedRoute.snapshot.params['id'];
        this.refresh();
    }

    refresh() {
        this.blogInfoService.detail(this.blogId).subscribe(data => {
            if (data) {
                this.data = data;
                if (this.data && this.data['content']) {
                    this.contentData = this.data['content'];
                }
            } else {
                this.router.navigateByUrl('blogs');
            }
        }, error => {
            this.router.navigateByUrl('blogs');
        });
    }

    submit() {
        const params = {
            'content': this.contentData ? this.contentData : ''
        };
        this.blogInfoService.update_content(this.blogId, params).subscribe(data => {
            if (data) {
                this.submitting = false;
                this.submitted = false;
            }
        }, error => {
            this.submitting = false;
            this.submitted = false;
        });
    }

}
