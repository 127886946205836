import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ArticlesRoutingModule } from './articles-routing.module';
import { AddArticleComponent } from './add-article/add-article.component';
import { ArticleInfoComponent } from './article-info/article-info.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {QuillModule} from "ngx-quill";


@NgModule({
    declarations: [AddArticleComponent, ArticleInfoComponent],
    exports: [
        AddArticleComponent
    ],
    imports: [
        CommonModule,
        ArticlesRoutingModule,
        ReactiveFormsModule,
        QuillModule,
        FormsModule
    ]
})
export class ArticlesModule { }
