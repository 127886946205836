<div class="slideout-content">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog">
            <form class="add-new-user modal-content pt-0">
                <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        (click)="toggleSidebar()"
                >
                    ×
                </button>
                <div class="modal-header">
                    <h5 class="modal-title" *ngIf="!data || !data['id']">Add News Categories</h5>
                    <h5 class="modal-title" *ngIf="data && data['id']">Update News Categories</h5>
                </div>
                <div class="modal-body flex-grow-1">
                    <form class="form mt-2 shadow-none" novalidate [formGroup]="addNewsCategoryForm">
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="name">News Category</label>
                                        <input class="form-control" id="name" type="text" formControlName="name"/>
                                        <small *ngIf="submitted && addNewsCategoryForm.get('name').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="alias">Alias</label>
                                        <input class="form-control" id="alias" type="text" formControlName="alias"/>
                                        <small *ngIf="submitted && addNewsCategoryForm.get('alias').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
<!--                            <div class="row mb-2">-->
<!--                                <div class="col-12">-->
<!--                                    <div class="form-group mb-0">-->
<!--                                        <label for="tags">Tags</label>-->
<!--                                        <textarea class="form-control" formControlName="tags"-->
<!--                                                  (keyup.space)="addTags($event.target.value)" id="tags" cols="30"-->
<!--                                                  rows="3" style="resize: none"></textarea>-->
<!--                                        <small *ngIf="submitted && addNewsCategoryForm.get('tags').hasError('required')"-->
<!--                                               class="text-danger">-->
<!--                                            Field is required.-->
<!--                                        </small>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
                            <div class="row">
                                <div class="col-12">
                                    <button type="button" rippleEffect class="btn btn-danger" data-dismiss="modal"
                                            aria-label="Close" (click)="toggleSidebar()">
                                        Cancel
                                    </button>
                                    <button type="submit" rippleEffect class="btn btn-primary ml-1 pull-right"
                                            (click)="submit()" *ngIf="!data || !data['id']">Submit
                                    </button>
                                    <button type="submit" rippleEffect class="btn btn-primary ml-1 pull-right"
                                            (click)="update()" *ngIf="data && data['id']">Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </form>
        </div>
    </div>
</div>

