<div class="content-wrapper">
    <div class="content-body">
        <section id="articles">
            <ng-template #content>
                <div id="modal">
                    <div class="modal-header font-weight-bold">
                        <h4 class="modal-title font-weight-bold">Author Content - {{viewContentData?.heading}}</h4>
                        <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                (click)="modalService.dismissAll('')"
                        >
                            ×
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row p-1">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                 [innerHTML]="viewContentData['content']"></div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <h2 class="mt-5px">Authors</h2>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <button class="btn btn-primary pull-right color-white color-white users-btn"
                            (click)="showSearch = !showSearch">
                        <i aria-hidden="true" class="fa fa-search mr-8px"></i>&nbsp;Search
                    </button>
                    <button class="btn btn-primary pull-right color-white color-white users-btn mr-2 cursor-pointer"
                            (click)="add()">
                        <i aria-hidden="true" class="fa fa-plus-circle mr-8px"></i>&nbsp;Add Author
                    </button>
                </div>
            </div>
            <div class="row search-card mt-3" *ngIf="showSearch">
                <div class="col-12">
                    <form class="form mt-2 mb--8px" novalidate [formGroup]="searchForm">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" type="text" formControlName="name"
                                           placeholder="name">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12 text-center">
                                <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                                        (click)="search()"
                                        [disabled]="submitting">
                                    <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submitting"></i>Search
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row d-none d-md-block overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list">
                                        <thead>
                                        <tr>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Name</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Short
                                                Description</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Cover</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Profile Image</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="authors && authors.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let author of authors; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{author?.name}}</h6></td>
                                            <td class="text-center"><h6 class="mt-1">{{author?.short_description}}</h6></td>
                                            <td class="text-center">
                                                <div class="btn-group" role="group"
                                                     *ngIf="author && author['author_cover']">
                                                    <button type="button" class="btn btn-primary" title="Upload Cover"
                                                            (click)="bannerUpload.click()">
                                                        <i class="fas fa-cloud-upload-alt"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-primary" title="View Cover"
                                                            (click)="openAuthorCover(author)">
                                                        <i class="fas fa-eye"></i>
                                                    </button>
                                                </div>
                                                <button class="btn btn-primary color-white" title="Upload"
                                                        (click)="bannerUpload.click()"
                                                        *ngIf="author && !author['author_cover']">
                                                    <i class="fas fa-cloud-upload-alt"></i>
                                                </button>
                                                <input class="form-control-file display-hidden" type="file"
                                                       (change)="onFileChange($event, 'AUTHOR_COVER', author)"
                                                       #bannerUpload>
                                            </td>
                                            <td class="text-center">
                                                <div class="btn-group" role="group"
                                                     *ngIf="author && author['author_image']">
                                                    <button type="button" class="btn btn-primary" title="Upload Profile Image"
                                                            (click)="profileUpload.click()">
                                                        <i class="fas fa-cloud-upload-alt"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-primary" title="View Profile Image"
                                                            (click)="openAuthorImage(author)">
                                                        <i class="fas fa-eye"></i>
                                                    </button>
                                                </div>
                                                <button class="btn btn-primary color-white" title="Upload"
                                                        (click)="profileUpload.click()"
                                                        *ngIf="author && !author['author_image']">
                                                    <i class="fas fa-cloud-upload-alt"></i>
                                                </button>
                                                <input class="form-control-file display-hidden" type="file"
                                                       (change)="onFileChange($event, 'AUTHOR_IMAGE', author)"
                                                       #profileUpload>
                                            </td>
                                            <td class="text-center">
                                                <i aria-hidden="true"
                                                   class="fas fa-edit f_20 text-info cursor-pointer font-weight-light mt-1"
                                                   title="Edit" (click)="edit(author)"></i>
                                                <i aria-hidden="true"
                                                   class="fab fa-artstation f_20 mt-1 text-primary cursor-pointer font-weight-light ml-5px"
                                                   title="Perform Actions" (click)="authorsInfoPage(author)"></i>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td colspan="7" class="text-center"><h6>No data to show</h6></td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 d-block d-md-none" *ngIf="authors && authors.length > 0; else noDataToShowMobile">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card" *ngFor="let author of authors; let i = index">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-6">
                                    <h4
                                            class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        . {{author?.name}}</h4>
                                </div>
                                <div class="col-6">
                                    <i aria-hidden="true"
                                       class="fas fa-edit f_20 text-info cursor-pointer font-weight-light pull-right"
                                       title="Edit" (click)="edit(author)"></i>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-2">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Name - {{author?.heading}}
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Content - {{author?.content}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination (pageChange)="onPageChange($event)"
                                [(page)]="pagination.page"
                                [boundaryLinks]="true"
                                [collectionSize]="pagination.total"
                                [maxSize]="5"
                                [pageSize]="pagination.perpage">
                </ngb-pagination>
            </div>
        </section>
    </div>
    <core-sidebar
            class="modal modal-slide-in sidebar-todo-modal fade"
            name="add-author"
            overlayClass="modal-backdrop">
        <app-add-author [data]="data" (valueSubmitted)="getData($event)"></app-add-author>
    </core-sidebar>
</div>

