import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CoreSidebarService } from "../../../../@core/components/core-sidebar/core-sidebar.service";
import { AlertService } from "../../../shared/services/alert.service";
import { HttpClient } from "@angular/common/http";
import { GlobalService } from "../../../shared/services/global.service";
import { ArticleService } from "../../../shared/services/article.service";
import { NewVideosService } from "../../../shared/services/new-videos.service";
import { NewsCategoryService } from "../../../shared/services/news-category.service";
import {AuthorService} from '../../../shared/services/author.service';

@Component({
    selector: 'app-add-news-videos',
    templateUrl: './add-news-videos.component.html',
    styleUrls: ['./add-news-videos.component.scss']
})
export class AddNewsVideosComponent implements OnChanges {

    addNewsVideoForm: FormGroup;
    id: any;
    submitted: any = false;
    submitting: any = false;
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;
    cities = [];
    newsVideosCategories: any = [];
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    authorList:any;
    constructor(private _coreSidebarService: CoreSidebarService,
        private fb: FormBuilder,
        private alertService: AlertService,
        public http: HttpClient,
        public globalService: GlobalService,
        private newsVideoService: NewVideosService,

        private authorService:AuthorService,
        private newsCategoryService: NewsCategoryService) {
    }

    ngOnChanges(): void {
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('app-add-news-videos').toggleOpen();
    }

    refresh() {
        this.addNewsVideoForm = this.fb.group({
            'author_id': ['', Validators.required],
            'heading': ['', Validators.required],
            'frame_url': ['', Validators.required],
            'news_categories_id': ['', Validators.required],
        });
        //this.getCities();
        if (this.data && this.data['id']) {
            this.patchForm();
        }
        this.getArticleCategory();
        this.getAuthor();
    }

    patchForm() {
        this.addNewsVideoForm.patchValue({
            'author_id': (this.data && this.data['author_id']) ? this.data['author_id'] : '',
            'heading': (this.data && this.data['heading']) ? this.data['heading'] : '',
            'frame_url': (this.data && this.data['frame_url']) ? this.data['frame_url'] : '',
            'news_categories_id': (this.data && this.data['news_categories_id']) ? this.data['news_categories_id'] : ''
        });
    }

    getArticleCategory(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.newsVideosCategories = [];
        this.newsCategoryService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.newsVideosCategories = data['data'];
            } else {
                this.newsVideosCategories = [];
            }
        });
    }
    getAuthor() {
        this.authorService.get('').subscribe(data => {
            this.authorList = data['data'];
        });
    }

    submit() {
        this.submitted = true;
        if (!this.addNewsVideoForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'author_id': (this.addNewsVideoForm.value && this.addNewsVideoForm.value.author_id) ? this.addNewsVideoForm.value.author_id : '',
                'heading': (this.addNewsVideoForm.value && this.addNewsVideoForm.value.heading) ? this.addNewsVideoForm.value.heading : '',
                'frame_url': (this.addNewsVideoForm.value && this.addNewsVideoForm.value.frame_url) ? this.addNewsVideoForm.value.frame_url : '',
                'news_categories_id': (this.addNewsVideoForm.value && this.addNewsVideoForm.value.news_categories_id) ? this.addNewsVideoForm.value.news_categories_id : ''
            };
            this.submitting = true;
            this.newsVideoService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addNewsVideoForm.reset();
                    this.addNewsVideoForm.patchValue({
                        'author_id': '',
                        'heading': '',
                        'frame_url': '',
                        'news_categories_id': ''
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].author_id && error['error']['errors'].author_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].author_id[0]);
                        return;
                    }
                    if (error['error']['errors'].heading && error['error']['errors'].heading.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].heading[0]);
                        return;
                    }
                    if (error['error']['errors'].frame_url && error['error']['errors'].frame_url.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].frame_url[0]);
                        return;
                    }
                    if (error['error']['errors'].news_categories_id && error['error']['errors'].news_categories_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].news_categories_id[0]);
                        return;
                    }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addNewsVideoForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'heading': (this.addNewsVideoForm.value && this.addNewsVideoForm.value.heading) ? this.addNewsVideoForm.value.heading : '',
                'frame_url': (this.addNewsVideoForm.value && this.addNewsVideoForm.value.frame_url) ? this.addNewsVideoForm.value.frame_url : '',
                'news_categories_id': (this.addNewsVideoForm.value && this.addNewsVideoForm.value.news_categories_id) ? this.addNewsVideoForm.value.news_categories_id : ''
            };
            this.submitting = true;
            this.newsVideoService.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].heading && error['error']['errors'].heading.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].heading[0]);
                        return;
                    }
                }
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].frame_url && error['error']['errors'].frame_url.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].frame_url[0]);
                        return;
                    }
                }
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].news_categories_id && error['error']['errors'].news_categories_id.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].news_categories_id[0]);
                        return;
                    }
                }
            });
        }
    }

}
