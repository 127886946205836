import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';
import {UploadService} from './upload.service';

@Injectable({
    providedIn: 'root'
})
export class ArticleService extends BaseService {
    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService,
                private fileUploadService: UploadService) {
        super(http, alertService, globalService);
    }

    get(data): Observable<any> {
        return this.getRequest(AppUrl.ARTICLES(), data);
    }

    detail(id): Observable<any> {
        return this.getRequest(AppUrl.ARTICLES(id));
    }

    create(data): Observable<any> {
        return this.postRequest(AppUrl.ARTICLES(), data);
    }

    update(id, data): Observable<any> {
        return this.putRequest(AppUrl.ARTICLES_UPDATE(id), data);
    }

    update_content(id, data): Observable<any> {
        return this.putRequest(AppUrl.ARTICLES_CONTENT(id), data);
    }

    updatePassword(id, data): Observable<any> {
        return this.putRequest(AppUrl.ADMIN_PASSWORD(id), data);
    }

    updateStatus(id, data): Observable<any> {
        return this.putRequest(AppUrl.ARTICLES_STATUS(id), data);
    }
    fileUpload(params, event): Observable<any> {
        return this.fileUploadService.fileUpload(AppUrl.FILE_UPLOAD(), params, event);
    }

    fileUploadAdmin(params, event): Observable<any> {
        return this.fileUploadService.fileUpload(AppUrl.FILE_UPLOAD_ADMIN(), params, event);
    }
    delete(id): Observable<any> {
        return this.deleteRequest(AppUrl.ARTICLES_DELETE(id));
    }


}
