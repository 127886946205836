import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BlogCategoriesComponent} from "./blog-categories.component";
import {AddBlogCategoryComponent} from "./add-category/add-blog-category.component";

const routes: Routes = [
    {
        path: 'blog-categories',
        component: BlogCategoriesComponent,
        data: {animation: 'blog-categories'}
    },
    {
        path: 'blog-category/add',
        component: AddBlogCategoryComponent,
        data: {animation: 'add/blog-categories'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BlogCategoriesRoutingModule {
}
