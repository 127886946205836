import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NewsVideosRoutingModule } from './news-videos-routing.module';
import { AddNewsVideosComponent } from './add-news-videos/add-news-videos.component';
import {ReactiveFormsModule} from "@angular/forms";


@NgModule({
    declarations: [AddNewsVideosComponent],
    exports: [
        AddNewsVideosComponent
    ],
    imports: [
        CommonModule,
        NewsVideosRoutingModule,
        ReactiveFormsModule
    ]
})
export class NewsVideosModule { }
