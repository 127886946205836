import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ArticlesComponent} from './articles.component';
import {AddArticleComponent} from './add-article/add-article.component';
import {ArticleInfoComponent} from './article-info/article-info.component';

const routes: Routes = [
    {
        path: 'articles',
        component: ArticlesComponent,
        data: {animation: 'articles'}
    },
    {
        path: 'article/add',
        component: AddArticleComponent,
        data: {animation: 'article/add'}
    },
    {
        path: 'article-info/:id',
        component: ArticleInfoComponent,
        data: {animation: 'article/Info'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ArticlesRoutingModule {
}
