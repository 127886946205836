import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BreakingNewsComponent} from "./breaking-news.component";

const routes: Routes = [
    {
        path: 'breaking-news', component: BreakingNewsComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BreakingNewsRoutingModule {
}
