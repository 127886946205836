export const locale = {
    lang: 'en',
    data: {
        MENU: {
            DASHBOARD: 'Dashboard',
            CATEGORIES: 'Categories',
            ADMINS: 'Admins',
            ARTICLES: 'Articles',
            BLOGS: 'Blogs',
            NEWSVIDEOS: 'News-videos',
            ARTICLECATEGORIES: 'Article-categories',
            NEWSCATEGORIES: 'News-categories',
            AUTHOR: 'Author',
            BANNER: 'Banner',
            BLOGCATEGORIES: 'blog-categories',
            META: 'meta',
            WEBPAGEBANNER: 'webpage-banner',
            MAGAZINE: 'magazine',
            NEW_PAPERS: 'news-papers',
            BREAKINGNEWS: 'breaking-news',
            AWARDS: 'awards'
        }
    }
};
