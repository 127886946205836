import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AwardsComponent} from './awards.component';
import {WebpageBannerComponent} from '../webpage-banner/webpage-banner.component';
import {AddWebpageComponent} from '../webpage-banner/add-webpage/add-webpage.component';
import {AddAwardsComponent} from './add-awards/add-awards.component';

const routes: Routes = [
  {
    path: 'awards',
    component: AwardsComponent,
    data: {animation: 'awards'}
  },
  {
    path: 'add-awards',
    component: AddAwardsComponent,
    data: {animation: 'add-awards'}
  }


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AwardsRoutingModule { }
