import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../../../shared/services/global.service';
import {NewsCategoryService} from '../../../shared/services/news-category.service';

@Component({
    selector: 'app-add-news-category',
    templateUrl: './add-news-category.component.html',
    styleUrls: ['./add-news-category.component.scss']
})
export class AddNewsCategoryComponent implements OnChanges {
    addNewsCategoryForm: FormGroup;
    id: any;
    submitted: any = false;
    submitting: any = false;
    insights: any = [];
    allInsight: any = [];
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;
    // tags: string[] = [];

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                public http: HttpClient,
                public globalService: GlobalService,
                private newsCategoryService: NewsCategoryService,) {
    }

    ngOnChanges(): void {
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('app-add-news-category').toggleOpen();
    }

    refresh() {
        this.addNewsCategoryForm = this.fb.group({
            'name': ['', (!this.id) ? Validators.required : ''],
            'alias': ['', (!this.id) ? Validators.required : ''],
            // 'tags': ['', (!this.id) ? Validators.required : '']
        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
    }

    // addTags(tag: string) {
    //     this.tags = tag.trim().split(' ');
    // }

    patchForm() {
        if (this.data) {
            this.getInsight(this.data['id']);
        }
        this.addNewsCategoryForm.patchValue({
            'name': (this.data && this.data['name']) ? this.data['name'] : '',
            'alias': (this.data && this.data['alias']) ? this.data['alias'] : '',
            // 'tags': (this.data && this.data['tags']) ? this.data['tags'].replace(/,/g, ' ') : ''
        });
    }

    getInsight(id) {
        this.insights = [];
        this.newsCategoryService.get(id).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.allInsight = (data && data['data']) ? data['data'] : [];
            } else {
                this.allInsight = [];
            }
        });
    }


    submit() {
        this.submitted = true;
        if (!this.addNewsCategoryForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.addNewsCategoryForm.value && this.addNewsCategoryForm.value.name) ? this.addNewsCategoryForm.value.name : '',
                'alias': (this.addNewsCategoryForm.value && this.addNewsCategoryForm.value.alias) ? this.addNewsCategoryForm.value.alias : '',
                // 'tags': this.tags ? this.tags : ''
            };
            this.submitting = true;
            this.newsCategoryService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addNewsCategoryForm.reset();
                    this.addNewsCategoryForm.patchValue({
                        'name': '',
                        'alias': '',
                        // 'tags': ''
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].alias && error['error']['errors'].alias.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].alias[0]);
                        return;
                    }
                    // if (error['error']['errors'].tags) {
                    //     this.alertService.showErrors(error['error']['errors'].tags[0]);
                    //     return;
                    // }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addNewsCategoryForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.addNewsCategoryForm.value && this.addNewsCategoryForm.value.name) ? this.addNewsCategoryForm.value.name : '',
                'alias': (this.addNewsCategoryForm.value && this.addNewsCategoryForm.value.alias) ? this.addNewsCategoryForm.value.alias : '',
                // 'tags': this.tags ? this.tags : ''
            };
            this.submitting = true;
            this.newsCategoryService.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
                this.addNewsCategoryForm.reset();
                this.addNewsCategoryForm.patchValue({
                    'name': '',
                    'alias': '',
                    // 'tags': ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error['error']['errors'].alias && error['error']['errors'].name.length > 0) {
                    this.alertService.showErrors(error['error']['errors'].name[0]);
                    return;
                }
                if (error['error']['errors'].alias && error['error']['errors'].alias.length > 0) {
                    this.alertService.showErrors(error['error']['errors'].alias[0]);
                    return;
                }
                // if (error['error']['errors'].tags) {
                //     this.alertService.showErrors(error['error']['errors'].tags[0]);
                //     return;
                // }
            });
        }
    }
}
