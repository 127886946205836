import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {WebpageBannerComponent} from "./webpage-banner.component";
import {AddWebpageComponent} from "./add-webpage/add-webpage.component";

const routes: Routes = [
    {
        path: 'webpage-banner',
        component: WebpageBannerComponent,
        data: {animation: 'webpage-banner'}
    },
    {
        path: 'add-webpage',
        component: AddWebpageComponent,
        data: {animation: 'add-webpage'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class WebpageBannerRoutingModule {
}
