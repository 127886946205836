import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthorRoutingModule} from './author-routing.module';
import {AddAuthorComponent} from './add-author/add-author.component';
import {AuthorComponent} from './author.component';
import {ReactiveFormsModule} from '@angular/forms';
import {CoreSidebarModule} from '../../../@core/components';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreCommonModule} from '../../../@core/common.module';
import { AddAuthorInfoComponent } from './add-author-info/add-author-info.component';
import {QuillModule} from 'ngx-quill';


@NgModule({
    declarations: [AddAuthorComponent, AddAuthorInfoComponent],
    exports: [
        AddAuthorComponent
    ],
    imports: [
        CommonModule,
        AuthorRoutingModule,
        CoreSidebarModule,
        NgbPaginationModule,
        CoreCommonModule,
        QuillModule
    ]
})
export class AuthorModule {
}
