import {Component, OnChanges, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {AdminService} from '../../shared/services/admin.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {BlogService} from '../../shared/services/blog.service';
import {AlertService} from '../../shared/services/alert.service';
import {AuthorService} from '../../shared/services/author.service';

@Component({
    selector: 'app-author',
    templateUrl: './author.component.html',
    styleUrls: ['./author.component.scss']
})
export class AuthorComponent implements OnInit {
    showSearch = false;
    id: any;
    data: any;
    submitted: any = false;
    submitting: any = false;
    searchForm: FormGroup;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    authors = [];
    onFileChanged: any;
    viewContentData: any;

    constructor(private router: Router,
                private _coreSidebarService: CoreSidebarService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private authorService: AuthorService,
                private alertService: AlertService) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-author').toggleOpen();
    }

    add(): void {
        this.data = undefined;
        this.toggleSidebar();
    }

    refresh() {
        this.searchForm = this.fb.group({
            'name': ['']
        });
        this.get();
    }


    getData(valueSubmit: any): void {
        if (valueSubmit) {
            this.get({page: 1, total: null, perpage: 15, pages: null});
            this.toggleSidebar();
        }
    }

    search() {
        const params = {
            'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : ''
        };
        this.get(params);
    }

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            name: (this.searchForm && this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : ''
        };
        this.get(params);
    }

    edit(data) {
        this.data = data;
        this.toggleSidebar();
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.authors = [];
        this.authorService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.authors = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.authors = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    authorsInfoPage(data: any) {
        this.router.navigateByUrl('author-info/' + data.id);
    }

    onFileChange(e, type, data) {
        this.onFileChanged = e;
        this.authorService.fileUploadAdmin({
            'type': type,
            'fileType': 'NORMAL',
            'author_file_id': data.id
        }, this.onFileChanged).subscribe(data => {
            if (type === 'AUTHOR_COVER' || type === 'AUTHOR_IMAGE') {
                if (data && data['data']) {
                    this.get();
                }
            }
            this.alertService.showSuccess({title: 'Success', message: 'Uploaded Successfully'});
        }, error => {
            this.alertService.showErrors('Something went wrong, please try again');
        });
    }

    openAuthorCover(data) {
        window.open(data['author_cover']['url'], '_blank');
    }
    openAuthorImage(data) {
        window.open(data['author_image']['url'], '_blank');
    }

    viewContent(content, data) {
        this.viewContentData = data;
        this.modalService.open(content);
    }

}
