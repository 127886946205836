import {Component, OnInit} from '@angular/core';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {AdminService} from '../../shared/services/admin.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BlogCategoryService} from '../../shared/services/blog-category.service';
import {AlertService} from '../../shared/services/alert.service';

@Component({
    selector: 'app-blog-categories',
    templateUrl: './blog-categories.component.html',
    styleUrls: ['./blog-categories.component.scss']
})
export class BlogCategoriesComponent implements OnInit {
    data: any;
    id: any;
    showSearch = false;
    admins = [];
    searchForm: FormGroup;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    submitted: any = false;
    submitting: any = false;
    updateCategoryForm: FormGroup;
    inputTypePassword: any;
    blogCategories: any = [];
    onFileChanged: any;

    constructor(private _coreSidebarService: CoreSidebarService,
                private blogCategoryService: BlogCategoryService,
                public modalService: NgbModal,
                private fb: FormBuilder,
                private alertService: AlertService) {
    }

    ngOnInit(): void {
    this.refresh();
    }

    refresh() {
        this.updateCategoryForm = this.fb.group({
            'password': ['']
        });
        this.searchForm = this.fb.group({
            'name': [''],
        });
        this.get();
    }

    add(): void {
        this.data = undefined;
        this.toggleSidebar();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-category').toggleOpen();
    }

    search() {
        const params = {
            'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
        };
        this.get(params);
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.submitting = true;
        this.blogCategories = [];
        this.blogCategoryService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.blogCategories = data['data'];
                this.pagination = {
                    page: data.current_page,
                    total: data.total,
                    perpage: data.per_page,
                    pages: data.last_page,
                };
            } else {
                this.blogCategories = [];
            }
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    onFileChange(e, type, data) {
        this.onFileChanged = e;
        this.blogCategoryService.fileUploadAdmin({
            'type': type,
            'fileType': 'NORMAL',
            'insight_id': data.id
        }, this.onFileChanged).subscribe(data => {
            if (type === 'BLOG_CATEGORY_BANNER') {
                if (data && data['data']) {
                    this.get();
                }
            }
            this.alertService.showSuccess({title: 'Success', message: 'Uploaded Successfully'});
        }, error => {
            this.alertService.showErrors('Something went wrong, please try again');
        });
    }

    openInsightBanner(data) {
        window.open(data['blog_category_banner']['url'], '_blank');
    }

    getData(valueSubmit: any): void {
        if (valueSubmit) {
            this.get({page: 1, total: null, perpage: 15, pages: null});
            this.toggleSidebar();
        }
    }

    delete(id) {
        this.blogCategoryService.delete(id).subscribe(data => {
            this.get();
        });
    }

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'name': (this.searchForm && this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
        };
        this.get(params);
    }

    edit(data) {
        this.data = data;
        this.toggleSidebar();
    }

    updateStatus(data, status) {
        this.blogCategoryService.updateStatus(data.id, {'status': status}).subscribe(data => {
            this.submitting = false;
            this.submitted = false;
            this.get();
        }, error => {
            this.submitting = false;
            this.submitted = false;
        });
    }

}
