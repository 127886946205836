<div class="slideout-content">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog">
            <form class="add-new-user modal-content pt-0">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleSidebar()">
                    ×
                </button>
                <div class="modal-header">
                    <h5 class="modal-title" *ngIf="!data || !data['id']">Add Metas</h5>
                    <h5 class="modal-title" *ngIf="data && data['id']">Update Metas</h5>
                </div>
                <div class="modal-body flex-grow-1">
                    <form class="form mt-2 shadow-none" novalidate [formGroup]="addMetaForm">
                        <div class="container">
                            <div class="row">


                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="blog_id">Blog</label>
                                        <select class="form-control" id="blog_id" type="text"
                                                formControlName="blog_id">
                                            <option value="">Select Blog</option>
                                            <option [value]="blog?.id"
                                                    *ngFor="let blog of blogList" class="blogListOption">
                                                {{blog?.heading}}</option>
                                        </select>
                                        <small
                                                *ngIf="submitted && addMetaForm.get('blog_id').hasError('required')"
                                                class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="title">Title</label>
                                        <input class="form-control" id="title" type="text" formControlName="title">
                                        <small *ngIf="submitted && addMetaForm.get('title').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="title">Meta Description</label>
                                        <input class="form-control" id="meta_description" type="text"
                                               formControlName="meta_description">
                                        <small *ngIf="submitted && addMetaForm.get('meta_description').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="title">Meta Keyword</label>
                                        <input class="form-control" id="meta_keyword" type="text"
                                               formControlName="meta_keyword">
                                        <small *ngIf="submitted && addMetaForm.get('meta_keyword').hasError('required')"
                                               class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="title">Meta_OG Title</label>
                                        <input class="form-control" id="meta_og_title" type="text"
                                               formControlName="meta_og_title">
                                        <!--                                        <small *ngIf="submitted && addMetaForm.get('meta_og_title').hasError('required')"-->
                                        <!--                                               class="text-danger">-->
                                        <!--                                            Field is required.-->
                                        <!--                                        </small>-->
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="title">Meta_OG Url</label>
                                        <input class="form-control" id="meta_og_url" type="text"
                                               formControlName="meta_og_url">
                                        <!--                                        <small *ngIf="submitted && addMetaForm.get('meta_og_url').hasError('required')"-->
                                        <!--                                               class="text-danger">-->
                                        <!--                                            Field is required.-->
                                        <!--                                        </small>-->
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="title">Meta OG Type</label>
                                        <input class="form-control" id="meta_og_type" type="text"
                                               formControlName="meta_og_type">
                                        <!--                                        <small *ngIf="submitted && addMetaForm.get('meta_og_type').hasError('required')"-->
                                        <!--                                               class="text-danger">-->
                                        <!--                                            Field is required.-->
                                        <!--                                        </small>-->
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="title">Meta OG Image</label>
                                        <input class="form-control" id="meta_og_image" type="text"
                                               formControlName="meta_og_image">
                                        <!--                                        <small *ngIf="submitted && addMetaForm.get('meta_og_image').hasError('required')"-->
                                        <!--                                               class="text-danger">-->
                                        <!--                                            Field is required.-->
                                        <!--                                        </small>-->
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="title">Meta OG Description</label>
                                        <input class="form-control" id="meta_og_description" type="text"
                                               formControlName="meta_og_description">
                                        <!--                                        <small *ngIf="submitted && addMetaForm.get('meta_og_description').hasError('required')"-->
                                        <!--                                               class="text-danger">-->
                                        <!--                                            Field is required.-->
                                        <!--                                        </small>-->
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="title">Meta Twitter Card</label>
                                        <input class="form-control" id="meta_twitter_card" type="text"
                                               formControlName="meta_twitter_card">
                                        <!--                                        <small *ngIf="submitted && addMetaForm.get('meta_twitter_card').hasError('required')"-->
                                        <!--                                               class="text-danger">-->
                                        <!--                                            Field is required.-->
                                        <!--                                        </small>-->
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="title">Meta Twitter Title</label>
                                        <input class="form-control" id="meta_twitter_title" type="text"
                                               formControlName="meta_twitter_title">
                                        <!--                                        <small *ngIf="submitted && addMetaForm.get('meta_twitter_title').hasError('required')"-->
                                        <!--                                               class="text-danger">-->
                                        <!--                                            Field is required.-->
                                        <!--                                        </small>-->
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="title">Meta Twitter Description</label>
                                        <input class="form-control" id="meta_twitter_description" type="text"
                                               formControlName="meta_twitter_description">
                                        <!--                                        <small *ngIf="submitted && addMetaForm.get('meta_twitter_description').hasError('required')"-->
                                        <!--                                               class="text-danger">-->
                                        <!--                                            Field is required.-->
                                        <!--                                        </small>-->
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="title">Meta Twitter URL</label>
                                        <input class="form-control" id="meta_twitter_url" type="text"
                                               formControlName="meta_twitter_url">
                                        <!--                                        <small *ngIf="submitted && addMetaForm.get('meta_twitter_url').hasError('required')"-->
                                        <!--                                               class="text-danger">-->
                                        <!--                                            Field is required.-->
                                        <!--                                        </small>-->
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="title">Meta Twitter Image</label>
                                        <input class="form-control" id="meta_twitter_image" type="text"
                                               formControlName="meta_twitter_image">
                                        <!--                                        <small *ngIf="submitted && addMetaForm.get('meta_twitter_image').hasError('required')"-->
                                        <!--                                               class="text-danger">-->
                                        <!--                                            Field is required.-->
                                        <!--                                        </small>-->
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <button type="button" rippleEffect class="btn btn-danger" data-dismiss="modal"
                                            aria-label="Close" (click)="toggleSidebar()">
                                        Cancel
                                    </button>
                                    <button type="submit" rippleEffect class="btn btn-primary ml-1 pull-right"
                                            (click)="submit()" *ngIf="!data || !data['id']">Submit
                                    </button>
                                    <button type="submit" rippleEffect class="btn btn-primary ml-1 pull-right"
                                            (click)="update()" *ngIf="data && data['id']">Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </form>
        </div>
    </div>
</div>
