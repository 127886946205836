import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AdminsComponent} from "./admins.component";
import {AddAdminComponent} from "./add-admin/add-admin.component";

const routes: Routes = [
  {
    path: 'admins',
    component: AdminsComponent,
    data: {animation: 'admin'}
  },
  {
    path: 'admin/add',
    component: AddAdminComponent,
    data: {animation: 'admin/add'}
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminsRoutingModule { }
