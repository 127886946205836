import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {BlogInfoComponent} from './blog-info/blog-info.component';
import {BlogsComponent} from './blogs.component';
import {AddBlogComponent} from './add-blog/add-blog.component';


const routes: Routes = [
  {
    path: 'blogs',
    component: BlogsComponent,
    data: {animation : 'blogs'}
  },
  {
    path: 'blog/add',
    component: AddBlogComponent,
    data: {animation : 'blog/add'}
  },
  {
    path: 'blog-info/:id',
    component: BlogInfoComponent,
    data: {animation : 'blog-info'}
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BlogsRoutingModule { }
