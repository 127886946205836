import { Component, OnInit } from '@angular/core';
import {Subject} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {BlogService} from '../../../shared/services/blog.service';
import {AuthorService} from '../../../shared/services/author.service';

@Component({
  selector: 'app-add-author-info',
  templateUrl: './add-author-info.component.html',
  styleUrls: ['./add-author-info.component.scss']
})
export class AddAuthorInfoComponent implements OnInit {

  public data: any;
  public toggleMenu = false;
  private _unsubscribeAll: Subject<any>;
  submitting: any = false;
  submitted: any = false;
  contentData: any;
  public authorId: any;

  constructor(private sanitizer: DomSanitizer,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private authorInfoService: AuthorService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.authorId = this.activatedRoute.snapshot.params['id'];
    this.refresh();
  }

  refresh() {
    this.authorInfoService.detail(this.authorId).subscribe(data => {
      if (data) {
        this.data = data;

        if (this.data && this.data['content']) {
          this.contentData = this.data['content'];
        }
      } else {
        this.router.navigateByUrl('author');
      }
    }, error => {
      this.router.navigateByUrl('author');
    });
  }

  submit() {
    const params = {
      'content': this.contentData ? this.contentData : ''
    };
    this.authorInfoService.update_content(this.authorId, params).subscribe(data => {
      if (data) {
        this.submitting = false;
        this.submitted = false;
      }
    }, error => {
      this.submitting = false;
      this.submitted = false;
    });
  }

}
