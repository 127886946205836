import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BlogCategoriesRoutingModule} from './blog-categories-routing.module';
import {AddBlogCategoryComponent} from './add-category/add-blog-category.component';
import {CoreSidebarModule} from "../../../@core/components";
import {NgbPaginationModule} from "@ng-bootstrap/ng-bootstrap";
import {CoreCommonModule} from "../../../@core/common.module";


@NgModule({
    declarations: [AddBlogCategoryComponent],
    imports: [
        CommonModule,
        BlogCategoriesRoutingModule,
        CoreSidebarModule,
        NgbPaginationModule,
        CoreCommonModule
    ],
    exports: [
        AddBlogCategoryComponent
    ]
})
export class BlogCategoriesModule {
}
