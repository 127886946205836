import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {NewsVideosComponent} from './news-videos.component';
import {AddNewsVideosComponent} from './add-news-videos/add-news-videos.component';

const routes: Routes = [
  {
    path: 'news-videos',
    component: NewsVideosComponent,
    data: {animation : 'news-videos'}
  },
  {
    path: 'news-videos/add',
    component: AddNewsVideosComponent,
    data: {animation : 'news-videos/add'}
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NewsVideosRoutingModule { }
