<div class="slideout-content">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog">
            <form class="add-new-user modal-content pt-0">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleSidebar()">
                    ×
                </button>
                <div class="modal-header">
                    <h5 class="modal-title" *ngIf="!data || !data['id']">Add News Video</h5>
                    <h5 class="modal-title" *ngIf="data && data['id']">Update News Video</h5>
                </div>
                <div class="modal-body flex-grow-1">
                    <form class="form mt-2 shadow-none" novalidate [formGroup]="addNewsVideoForm">
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="author_id">Author</label>
                                        <select class="form-control" id="author_id" type="text"
                                                formControlName="author_id">
                                            <option value="">Select Author</option>
                                            <option [value]="author?.id"
                                                    *ngFor="let author of authorList">
                                                {{author?.name}}</option>
                                        </select>
                                        <small
                                                *ngIf="submitted && addNewsVideoForm.get('author_id').hasError('required')"
                                                class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <!-- {{ newsVideosCategories | json }} -->
                                    <div class="form-group">
                                        <label for="news_categories_id">News Categories</label>
                                        <select class="form-control" id="news_categories_id"
                                            formControlName="news_categories_id">
                                            <option value="" disabled selected>Select News Categories</option>
                                            <option [value]="newsVideo.id"
                                                *ngFor="let newsVideo of newsVideosCategories">
                                                {{newsVideo?.name}}
                                            </option>
                                        </select>
                                        <small
                                            *ngIf="submitted && addNewsVideoForm.get('news_categories_id').hasError('required')"
                                            class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="heading">Heading</label>
                                        <input class="form-control" id="heading" formControlName="heading"
                                            placeholder="Heading" />
                                        <small *ngIf="submitted && addNewsVideoForm.get('heading').hasError('required')"
                                            class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="frame_url">Frame Url</label>
                                        <textarea class="form-control resize-none" rows="3" id="frame_url"
                                            formControlName="frame_url">
                                        </textarea>
                                        <small
                                            *ngIf="submitted && addNewsVideoForm.get('frame_url').hasError('required')"
                                            class="text-danger">
                                            Field is required.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <button type="button" rippleEffect class="btn btn-danger" data-dismiss="modal"
                                        aria-label="Close" (click)="toggleSidebar()">
                                        Cancel
                                    </button>
                                    <button type="submit" rippleEffect class="btn btn-primary ml-1 pull-right"
                                        (click)="submit()" *ngIf="!data || !data['id']">Submit
                                    </button>
                                    <button type="submit" rippleEffect class="btn btn-primary ml-1 pull-right"
                                        (click)="update()" *ngIf="data && data['id']">Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </form>
        </div>
    </div>
</div>
