import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';
import {UploadService} from './upload.service';

@Injectable({
  providedIn: 'root'
})
export class MetaService extends BaseService {
  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService,
              private fileUploadService: UploadService) {
    super(http, alertService, globalService);
  }

  get(data): Observable<any> {
    return this.getRequest(AppUrl.METAS(), data);
  }

  detail(id): Observable<any> {
    return this.getRequest(AppUrl.METAS(id));
  }

  create(data): Observable<any> {
    return this.postRequest(AppUrl.METAS(), data);
  }

  update(id, data): Observable<any> {
    return this.putRequest(AppUrl.METAS(id), data);
  }
  delete(id): Observable<any> {
    return this.deleteRequest(AppUrl.METAS(id));
  }

  update_content(id, data): Observable<any> {
    return this.putRequest(AppUrl.METAS_CONTENT(id), data);
  }


  updateStatus(id, data): Observable<any> {
    return this.putRequest(AppUrl.BLOG_STATUS(id), data);
  }
}
