import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {BlogCategoryService} from '../../../shared/services/blog-category.service';


@Component({
    selector: 'app-add-category',
    templateUrl: './add-blog-category.component.html',
    styleUrls: ['./add-blog-category.component.scss']
})
export class AddBlogCategoryComponent implements OnInit {
    addCategoryForm: FormGroup;
    inputTypePassword: any = 'password';
    submitted: any = false;
    submitting: any = false;
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;
    categories: any;
    allCategories: any;
    // tags: string[] = [];


    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                private categoryService: BlogCategoryService) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    ngOnChanges(): void {
        this.refresh();
    }

    refresh() {
        this.addCategoryForm = this.fb.group({
            'name': ['', Validators.required],
            'alias': ['', Validators.required],
            // 'tags': ['', Validators.required]
        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
    }

    // addTags(tag: string) {
    //     this.tags = tag.trim().split(' ');
    // }

    patchForm() {
        if (this.data) {
            this.getCategory(this.data['id']);

        }
        this.addCategoryForm.patchValue({
            'name': (this.data && this.data['name']) ? this.data['name'] : '',
            'alias': (this.data && this.data['alias']) ? this.data['alias'] : '',
            // 'tags': (this.data && this.data['tags']) ? this.data['tags'].replace(/,/g, ' ') : ''
        });
    }

    getCategory(id) {
        this.categories = [];
        this.categoryService.get(id).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.allCategories = (data && data['data']) ? data['data'] : [];
            } else {
                this.allCategories = [];
            }
        });
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-category').toggleOpen();
    }

    submit() {
        this.submitted = true;
        if (!this.addCategoryForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.addCategoryForm.value && this.addCategoryForm.value.name) ? this.addCategoryForm.value.name : '',
                'alias': (this.addCategoryForm.value && this.addCategoryForm.value.alias) ? this.addCategoryForm.value.alias : '',
                // 'tags': this.tags ? this.tags : '',
            };
            this.submitting = true;
            this.categoryService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addCategoryForm.reset();
                    this.addCategoryForm.patchValue({
                        'name': '',
                        'alias': '',
                        // 'tags': ''
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].alias && error['error']['errors'].alias.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].alias[0]);
                        return;
                    }
                    // if (error['error']['errors'].tags) {
                    //     this.alertService.showErrors(error['error']['errors'].tags[0]);
                    //     return;
                    // }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addCategoryForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.addCategoryForm.value && this.addCategoryForm.value.name) ? this.addCategoryForm.value.name : '',
                'alias': (this.addCategoryForm.value && this.addCategoryForm.value.alias) ? this.addCategoryForm.value.alias : '',
                // 'tags': this.tags ? this.tags : ''
            };
            this.submitting = true;
            this.categoryService.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
                this.addCategoryForm.reset();
                this.addCategoryForm.patchValue({
                    'name': '',
                    'alias': '',
                    // 'tags': ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].alias && error['error']['errors'].alias.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].alias[0]);
                        return;
                    }
                    // if (error['error']['errors'].tags) {
                    //     this.alertService.showErrors(error['error']['errors'].tags[0]);
                    //     return;
                    // }
                }
            });
        }
    }
}
