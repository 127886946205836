import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {CoreSidebarService} from '../../../@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MetaService} from '../../shared/services/meta.service';
import {AlertService} from '../../shared/services/alert.service';

@Component({
  selector: 'app-meta',
  templateUrl: './meta.component.html',
  styleUrls: ['./meta.component.scss']
})
export class MetaComponent implements OnInit {

  showSearch = false;
  id: any;
  data: any;
  submitted: any = false;
  submitting: any = false;
  searchForm: FormGroup;
  pagination = {
    page: 1,
    total: null,
    perpage: 15,
    pages: null
  };
  metas = [];
  onFileChanged: any;
  viewContentData: any;

  constructor(private router: Router,
              private _coreSidebarService: CoreSidebarService,
              public modalService: NgbModal,
              private fb: FormBuilder,
              private metaService: MetaService,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  toggleSidebar(): void {
    this._coreSidebarService.getSidebarRegistry('add-meta').toggleOpen();
  }

  edit(data) {
    this.data = data;
    this.toggleSidebar();
  }

  add(): void {
    this.data = undefined;
    this.toggleSidebar();
  }

  refresh() {
    this.searchForm = this.fb.group({
      'title': ['']
    });
    this.get();
  }


  getData(valueSubmit: any): void {
    if (valueSubmit) {
      this.get({page: 1, total: null, perpage: 15, pages: null});
      this.toggleSidebar();
    }
  }

  search() {
    const params = {
      'title': (this.searchForm.value && this.searchForm.value.title) ? this.searchForm.value.title : ''
    };
    this.get(params);
  }

  onPageChange(page) {
    this.pagination.page = page;
    const params = {
      category: (this.searchForm && this.searchForm.value && this.searchForm.value.category) ? this.searchForm.value.category : '',
      heading: (this.searchForm && this.searchForm.value && this.searchForm.value.heading) ? this.searchForm.value.heading : ''
    };
    this.get(params);
  }



  get(params: any = {}) {
    params.page = this.pagination.page;
    params.per_page = this.pagination.perpage;
    this.submitting = true;
    this.metas = [];

    this.metaService.get(params).subscribe(data => {
      if (data && data['data'].length > 0) {
        this.metas = data['data'];
        this.pagination = {
          page: data.current_page,
          total: data.total,
          perpage: data.per_page,
          pages: data.last_page,
        };
      } else {
        this.metas = [];
      }
      this.submitting = false;
    }, error => {
      this.submitting = false;
    });
  }

  metasInfoPage(data: any) {
    this.router.navigateByUrl('blog-info/' + data.id);
  }
  delete(id) {
    this.metaService.delete(id).subscribe(data => {
      this.get();
    });
  }
  onFileChange(e, type, data) {
    // this.onFileChanged = e;
    // this.metaService.fileUploadAdmin({
    //   'type': type,
    //   'fileType': 'NORMAL',
    //   'insight_id': data.id
    // }, this.onFileChanged).subscribe(data => {
    //   if (type === 'BLOG_BANNER' || type === 'BLOG_COVER') {
    //     if (data && data['data']) {
    //       this.get();
    //     }
    //   }
    //   this.alertService.showSuccess({title: 'Success', message: 'Uploaded Successfully'});
    // }, error => {
    //   this.alertService.showErrors('Something went wrong, please try again');
    // });
  }

  openBlogBanner(data) {
    window.open(data['blog_banner']['url'], '_blank');
  }
  openBlogCover(data) {
    window.open(data['blog_cover']['url'], '_blank');
  }

  viewContent(content, data) {
    this.viewContentData = data;
    this.modalService.open(content);
  }
  // updateStatus(data, status) {
  //   this.metaService.updateStatus(data.id, {'status': status}).subscribe(data => {
  //     this.submitting = false;
  //     this.submitted = false;
  //     this.get();
  //   }, error => {
  //     this.submitting = false;
  //     this.submitted = false;
  //   });
  // }

}
