import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BreakingNewsService} from "../../shared/services/breaking-news.service";
import {AlertService} from "../../shared/services/alert.service";

@Component({
    selector: 'app-breaking-news',
    templateUrl: './breaking-news.component.html',
    styleUrls: ['./breaking-news.component.scss']
})
export class BreakingNewsComponent implements OnInit {
    addBreakingNewsForm: FormGroup;
    breakingNews = [];
    submitted: any = false;
    submitting: any = false;
    searchForm: FormGroup;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };

    constructor(private router: Router,
                private fb: FormBuilder,
                private alertService: AlertService,
                private breakingNewsServices: BreakingNewsService) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.addBreakingNewsForm = this.fb.group({
            'news': ['', Validators.required],
        });
        this.get();
        this.patchForm();
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        params.id = 1;
        this.submitting = true;
        this.breakingNews = [];
        this.breakingNewsServices.get(params).subscribe(data => {
            this.breakingNews = data;
            this.addBreakingNewsForm.patchValue(
                {
                    'news': this.breakingNews && this.breakingNews['news'] ? this.breakingNews['news'] : ''
                }
            )
            this.submitting = false;
        }, error => {
            this.submitting = false;
        });
    }

    patchForm() {
        this.addBreakingNewsForm.patchValue({
            'news': (this.addBreakingNewsForm && this.addBreakingNewsForm['news']) ? this.addBreakingNewsForm['news'] : '',
        });
    }

    update() {
        this.submitted = true;
        if (!this.addBreakingNewsForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'news': (this.addBreakingNewsForm.value && this.addBreakingNewsForm.value.news) ? this.addBreakingNewsForm.value.news : '',
            };
            this.submitting = true;
            this.breakingNewsServices.update(this.breakingNews['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].news && error['error']['errors'].news.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].news[0]);
                        return;
                    }
                }
            });
        }
    }

}
