<div class="content-wrapper">
    <div class="content-body">
        <section id="articles">
            <ng-template #content>
                <div id="modal">
                    <div class="modal-header font-weight-bold">
                        <h4 class="modal-title font-weight-bold">NewsPaper - {{viewContentData?.heading}}</h4>
                        <button
                                (click)="modalService.dismissAll('')"
                                aria-label="Close"
                                class="close"
                                data-dismiss="modal"
                                type="button"
                        >
                            ×
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row p-1">
                            <div [innerHTML]="viewContentData['content']"
                                 class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"></div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <h2 class="mt-5px">News Paper</h2>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <button (click)="showSearch = !showSearch"
                            class="btn btn-primary pull-right color-white color-white users-btn">
                        <i aria-hidden="true" class="fa fa-search mr-8px"></i>&nbsp;Search
                    </button>
                    <button (click)="add()"
                            class="btn btn-primary pull-right color-white color-white users-btn mr-2 cursor-pointer">
                        <i aria-hidden="true" class="fa fa-plus-circle mr-8px"></i>&nbsp;Add NewsPaper
                    </button>
                </div>
            </div>
            <div *ngIf="showSearch" class="row search-card mt-3">
                <div class="col-12">
                    <form [formGroup]="searchForm" class="form mt-2 mb--8px" novalidate>
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" formControlName="title" placeholder="heading"
                                           type="text">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12 text-center">
                                <button (click)="search()"
                                        [disabled]="submitting"
                                        class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn">
                                    <i *ngIf="submitting" class="fas fa-spinner fa-pulse mr-1"></i>Search
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row d-none d-md-block overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list">
                                        <thead>
                                        <tr>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Heading</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Banner</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">NewsPaper PDF</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="newsPapers && newsPapers.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let newsPaper of newsPapers; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                                    .</h6>
                                            </td>
                                            <td class="text-center"><h6 class="mt-1">{{newsPaper?.title}}</h6></td>

                                            <td class="text-center">
                                                <div *ngIf="newsPaper && newsPaper['news_paper_cover']" class="btn-group"
                                                     role="group">
                                                    <button (click)="newsPaperCoverUpload.click()" class="btn btn-primary" title="Upload Banner"
                                                            type="button">
                                                        <i class="fas fa-cloud-upload-alt"></i>
                                                    </button>
                                                    <button (click)="openNewsPaperCover(newsPaper)" class="btn btn-primary" title="View Banner"
                                                            type="button">
                                                        <i class="fas fa-eye"></i>
                                                    </button>
                                                </div>
                                                <button (click)="newsPaperCoverUpload.click()" *ngIf="newsPaper && !newsPaper['news_paper_cover']"
                                                        class="btn btn-primary color-white mx-2"
                                                        title="Upload">
                                                    <i class="fas fa-cloud-upload-alt"></i>
                                                </button>
                                                <input #newsPaperCoverUpload (change)="onFileChange($event, 'NEWS_PAPER_COVER', newsPaper)"
                                                       class="form-control-file display-hidden"
                                                       type="file">
                                            </td>

                                            <td class="text-center">
                                                <div *ngIf="newsPaper && newsPaper['news_paper_pdf']" class="btn-group"
                                                     role="group">
                                                    <button (click)="pdfUpload.click()" class="btn btn-primary" title="Upload Banner"
                                                            type="button">
                                                        <i class="fas fa-cloud-upload-alt"></i>
                                                    </button>
                                                    <button (click)="openNewsPaperFile(newsPaper)" class="btn btn-primary" title="View Banner"
                                                            type="button">
                                                        <i class="fas fa-eye"></i>
                                                    </button>
                                                </div>
                                                <button (click)="pdfUpload.click()" *ngIf="newsPaper && !newsPaper['news_paper_pdf']"
                                                        class="btn btn-primary color-white mx-2"
                                                        title="Upload">
                                                    <i class="fas fa-cloud-upload-alt"></i>
                                                </button>
                                                <input #pdfUpload (change)="onFileChange($event, 'NEWS_PAPER_FILE', newsPaper)"
                                                       accept="application/pdf"
                                                       class="form-control-file display-hidden"
                                                       type="file">
                                            </td>
                                            <td class="text-center">
                                                <i (click)="edit(newsPaper)"
                                                   aria-hidden="true"
                                                   class="fas fa-edit f_20 text-info cursor-pointer font-weight-light mt-1" title="Edit"></i>
                                                <i (click)="delete(newsPaper.id)"
                                                   aria-hidden="true"
                                                   class="fas fa-trash f_20 ml-5px text-danger cursor-pointer mt-1" title="Delete"></i>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td class="text-center" colspan="7"><h6>No data to show</h6></td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="newsPapers && newsPapers.length > 0; else noDataToShowMobile"
                 class="row mt-3 d-block d-md-none">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div *ngFor="let newsPaper of newsPapers; let i = index" class="card">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-6">
                                    <h4 class="card-title text-uppercase card-ellipsis">
                                        {{(pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        .{{newsPaper?.name}}
                                    </h4>
                                </div>
                                <div class="col-6">
                                    <i (click)="edit(newsPaper)"
                                       aria-hidden="true"
                                       class="fas fa-edit f_20 text-info cursor-pointer font-weight-light mt-1" title="Edit"></i>
                                    <i (click)="edit(newsPaper)"
                                       aria-hidden="true"
                                       class="fas fa-edit f_20 text-info cursor-pointer font-weight-light pull-right" title="Edit"></i>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-2">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Heading - {{newsPaper?.title}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination (pageChange)="onPageChange($event)"
                                [(page)]="pagination.page"
                                [boundaryLinks]="true"
                                [collectionSize]="pagination.total"
                                [maxSize]="5"
                                [pageSize]="pagination.perpage">
                </ngb-pagination>
            </div>
        </section>
    </div>
    <core-sidebar
            class="modal modal-slide-in sidebar-todo-modal fade"
            name="add-news"
            overlayClass="modal-backdrop">
        <app-add-news (valueSubmitted)="getData($event)" [data]="data"></app-add-news>
    </core-sidebar>
</div>


