import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable()
export class AppUrl {

    static get API_URL(): string {
        return environment.appUrl + 'api/';
    }

    static get APP_URL(): string {
        return environment.appUrl + 'admin/';
    }

    static get LOGIN(): string {
        return AppUrl.APP_URL + 'login';
    }

    static get SELF(): string {
        return AppUrl.API_URL + 'self';
    }

    static UPDATE_SELF(): string {
        return AppUrl.API_URL + 'update-self';
    }

    static LOGOUT(): string {
        return AppUrl.API_URL + 'logout';
    }

    static FILE_UPLOAD(): string {
        return AppUrl.API_URL + 'file-upload';
    }

    static FILE_UPLOAD_ADMIN(): string {
        return AppUrl.APP_URL + 'file-upload-admin';
    }

    static get VERIFY_USER(): string {
        return AppUrl.APP_URL + 'verify-user';
    }

    static UPDATE_USER_PASSWORD(): string {
        return AppUrl.API_URL + 'update-user-password';
    }

    static STATES(): string {
        return AppUrl.APP_URL + 'states';
    }

    static COUNTRIES(): string {
        return AppUrl.APP_URL + 'countries';
    }

    static ADMINS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'admin/' + id;
        } else {
            return AppUrl.APP_URL + 'admin';
        }
    }

    static ADMIN_PASSWORD(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'admin-password/' + id;
        }
    }

    static ADMIN_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'admin-status/' + id;
        }
    }

    static ADMINS_COUNT(): string {
        return AppUrl.APP_URL + 'admins-count';
    }

    static SUBADMINS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'subAdmin/' + id;
        } else {
            return AppUrl.APP_URL + 'subAdmin';
        }
    }


    static APPOINTMENTS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'appointment/' + id;
        } else {
            return AppUrl.APP_URL + 'appointment';
        }
    }


    static UPDATE_VIDEO_URL(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'consult-video-url/' + id;
        }
    }


    static BLOG_CATEGORY(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'blog-category/' + id;
        } else {
            return AppUrl.APP_URL + 'blog-category';
        }
    }

    static BLOG_CATEGORY_COUNT(): string {
        return AppUrl.APP_URL + 'blog-category-count';
    }

    static BLOG_CATEGORY_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'blog-category-status/' + id;
        }
    }

    static SUB_CATEGORIES(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'product-sub-category/' + id;
        } else {
            return AppUrl.APP_URL + 'product-sub-category';
        }
    }

    static SUB_CATEGORIES_COUNT(): string {
        return AppUrl.APP_URL + 'product-sub-category-count';
    }

    static SUB_CATEGORY_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'product-sub-category-status/' + id;
        }
    }

    static ARTICLES(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'article/' + id;
        } else {
            return AppUrl.APP_URL + 'article';
        }
    }

    static ARTICLES_UPDATE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'article/' + id;
        } else {
            return AppUrl.APP_URL + 'article';
        }
    }

    static ARTICLES_CONTENT(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'article-content/' + id;
        }
    }

    static ARTICLES_CATEGORY(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'article-category/' + id;
        } else {
            return AppUrl.APP_URL + 'article-category';
        }
    }

    static ARTICLES_CATEGORY_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'article-category-status/' + id;
        }
    }

    static ARTICLES_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'article-status/' + id;
        }
    }

    static ARTICLES_DELETE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'article/' + id;
        }
    }

    static ARTICLES_CATEGORY_DELETE(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'article-category/' + id;
        }
    }

    static NEWS_CATEGORY(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'news-category/' + id;
        } else {
            return AppUrl.APP_URL + 'news-category';
        }
    }

    static NEWS_CATEGORY_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'news-category-status/' + id;
        }
    }

    static NEWS_VIDEOS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'news-video/' + id;
        } else {
            return AppUrl.APP_URL + 'news-video';
        }
    }

    static NEWS_VIDEOS_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'news-video-status/' + id;
        }
    }

    static BLOGS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'blog/' + id;
        } else {
            return AppUrl.APP_URL + 'blog';
        }
    }

    static BLOGS_CONTENT(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'blog-content/' + id;
        }
    }

    static BLOG_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'blog-status/' + id;
        }
    }

    static METAS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'meta/' + id;
        } else {
            return AppUrl.APP_URL + 'meta';
        }
    }

    static METAS_CONTENT(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'meta-content/' + id;
        }
    }

    static META_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'meta-status/' + id;
        }
    }

    static AUTHOR(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'author/' + id;
        } else {
            return AppUrl.APP_URL + 'author';
        }
    }

    static AUTHOR_CONTENT(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'author-content/' + id;
        }
    }


    static BANNERS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'banner/' + id;
        } else {
            return AppUrl.APP_URL + 'banner';
        }
    }

    static BANNERS_CONTENT(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'banner-content/' + id;
        }
    }

    static BANNERS_CATEGORY(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'banner-category/' + id;
        } else {
            return AppUrl.APP_URL + 'banner-category';
        }
    }

    static BANNERS_CATEGORY_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'banner-category-status/' + id;
        }
    }

    static BANNERS_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'banner-status/' + id;
        }
    }

    static MAGAZINES_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'magazine-status/' + id;
        }
    }

    static MAGAZINES(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'magazine/' + id;
        } else {
            return AppUrl.APP_URL + 'magazine';
        }
    }

    static NEWSPAPERS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'news-paper/' + id;
        } else {
            return AppUrl.APP_URL + 'news-paper';
        }
    }

    static NEWSPAPERS_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'news-paper/' + id;
        }
    }

    static BREAKING_NEWS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'breaking-news/' + id;
        } else {
            return AppUrl.APP_URL + 'breaking-news';
        }
    }

    static AWARDS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'awards/' + id;
        } else {
            return AppUrl.APP_URL + 'awards';
        }
    }
    static WEBPAGE_BANNER(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'webpage-banner/' + id;
        } else {
            return AppUrl.APP_URL + 'webpage-banner';
        }
    }

    static WEBPAGE_BANNER_STATUS(id?): string {
        if (id) {
            return AppUrl.APP_URL + 'webpage-banner-status/' + id;
        }
    }

}
