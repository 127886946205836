import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../../../shared/services/global.service';
import {BlogService} from '../../../shared/services/blog.service';
import {AuthorService} from '../../../shared/services/author.service';

@Component({
    selector: 'app-add-author',
    templateUrl: './add-author.component.html',
    styleUrls: ['./add-author.component.scss']
})
export class AddAuthorComponent implements OnInit, OnChanges {
    addAuthorForm: FormGroup;
    id: any;
    submitted: any = false;
    submitting: any = false;
    onFileChanged: any;
    fileCourseCover: any;
    authors: any = [];
    allAuthors: any = [];
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                public http: HttpClient,
                public globalService: GlobalService,
                private authorService: AuthorService,
    ) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(): void {
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-author').toggleOpen();
    }

    refresh() {
        this.addAuthorForm = this.fb.group({
            'name': ['', (!this.id) ? Validators.required : ''],
            'short_description': ['', (!this.id) ? Validators.required : ''],
        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
    }

    patchForm() {
        if (this.data) {
            this.getAuthor(this.data['id']);
        }
        this.addAuthorForm.patchValue({
            'name': (this.data && this.data['name']) ? this.data['name'] : '',
            'short_description': (this.data && this.data['short_description']) ? this.data['short_description'] : '',
        });
    }

    getAuthor(id) {
        this.authors = [];
        this.authorService.get(id).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.allAuthors = (data && data['data']) ? data['data'] : [];
            } else {
                this.allAuthors = [];
            }
        });
    }


    submit() {
        this.submitted = true;
        if (!this.addAuthorForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.addAuthorForm.value && this.addAuthorForm.value.name) ? this.addAuthorForm.value.name : '',
                'short_description': (this.addAuthorForm.value && this.addAuthorForm.value.short_description) ? this.addAuthorForm.value.short_description : '',
            };
            this.submitting = true;
            this.authorService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addAuthorForm.reset();
                    this.addAuthorForm.patchValue({
                        'name': '',
                        'short_description': '',
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].university_name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].about_course && error['error']['errors'].short_description.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].short_description[0]);
                        return;
                    }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addAuthorForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.addAuthorForm.value && this.addAuthorForm.value.name) ? this.addAuthorForm.value.name : '',
                'short_description': (this.addAuthorForm.value && this.addAuthorForm.value.short_description) ? this.addAuthorForm.value.short_description : '',
            };
            this.submitting = true;
            this.authorService.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
                this.addAuthorForm.reset();
                this.addAuthorForm.patchValue({
                    'name': '',
                    'short_description': '',
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].university_name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].about_course && error['error']['errors'].short_description.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].short_description[0]);
                        return;
                    }
                }
            });
        }
    }

    onFileChange(e, type, index?) {
        this.onFileChanged = e;
        this.authorService.fileUpload({'type': type, 'fileType': 'NORMAL'}, this.onFileChanged).subscribe(data => {
            if (type === 'BLOG_COVER') {
                this.fileCourseCover = data['data'];
            }
            this.alertService.showSuccess({title: 'Success', message: 'Uploaded Successfully'});
        }, error => {
            this.alertService.showErrors('Something went wrong, please try again');
        });
    }

    openInsightCover() {
        window.open(this.fileCourseCover['url'], '_blank');
    }

}
