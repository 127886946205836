import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../../../shared/services/global.service';
import {AuthorService} from '../../../shared/services/author.service';
import {BlogService} from '../../../shared/services/blog.service';
import {MetaService} from '../../../shared/services/meta.service';

@Component({
    selector: 'app-add-meta',
    templateUrl: './add-meta.component.html',
    styleUrls: ['./add-meta.component.scss']
})
export class AddMetaComponent implements OnInit {

    addMetaForm: FormGroup;
    id: any;
    submitted: any = false;
    submitting: any = false;
    onFileChanged: any;
    fileCourseCover: any;
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;
    selectedArticle: any;
    authorList: any;
    blogList: any = [];
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                public http: HttpClient,
                public globalService: GlobalService,
                private metaService: MetaService,
                private authorServices: AuthorService,
                private blogService: BlogService) {
    }

    ngOnInit(): void {
        this.getArticleCategory();
        this.getAuthor();
    }

    ngOnChanges(): void {
        this.refresh();
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-meta').toggleOpen();
    }

    refresh() {
        this.addMetaForm = this.fb.group({
            'title': ['', (!this.id) ? Validators.required : ''],
            'blog_id': ['', (!this.id) ? Validators.required : ''],
            'meta_description': ['', (!this.id) ? Validators.required : ''],
            'meta_keyword': ['', (!this.id) ? Validators.required : ''],
            'meta_og_title': [''],
            'meta_og_url': [''],
            'meta_og_type': [''],
            'meta_og_image': [''],
            'meta_og_description': [''],
            'meta_twitter_card': [''],
            'meta_twitter_title': [''],
            'meta_twitter_description': [''],
            'meta_twitter_url': [''],
            'meta_twitter_image': [''],
        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
        this.getArticleCategory();
    }

    patchForm() {
        // if (this.data) {
        //   this.getArticle(this.data['id']);
        // }
        this.addMetaForm.patchValue({
            // 'user_id':
            // 'author_id': (this.data && this.data['author_id']) ? this.data['author_id'] : '',
            'title': (this.data && this.data['title']) ? this.data['title'] : '',
            'blog_id': (this.data && this.data['blog_id']) ? this.data['blog_id'] : '',
            'meta_description': (this.data && this.data['meta_description']) ? this.data['meta_description'] : '',
            'meta_keyword': (this.data && this.data['meta_keyword']) ? this.data['meta_keyword'] : '',
            'meta_og_url': (this.data && this.data['meta_og_url']) ? this.data['meta_og_url'] : '',
            'meta_og_title': (this.data && this.data['meta_og_title']) ? this.data['meta_og_title'] : '',
            'meta_og_type': (this.data && this.data['meta_og_type']) ? this.data['meta_og_type'] : '',
            'meta_og_image': (this.data && this.data['meta_og_image']) ? this.data['meta_og_image'] : '',
            'meta_og_description': (this.data && this.data['meta_og_description']) ? this.data['meta_og_description'] : '',
            'meta_twitter_card': (this.data && this.data['meta_twitter_card']) ? this.data['meta_twitter_card'] : '',
            'meta_twitter_title': (this.data && this.data['meta_twitter_title']) ? this.data['meta_twitter_title'] : '',
            'meta_twitter_description': (this.data && this.data['meta_twitter_description']) ? this.data['meta_twitter_description'] : '',
            'meta_twitter_url': (this.data && this.data['meta_twitter_url']) ? this.data['meta_twitter_url'] : '',
            'meta_twitter_image': (this.data && this.data['meta_twitter_image']) ? this.data['meta_twitter_image'] : '',
        });
    }

    // getArticle(id) {
    //   this.selectedArticle = {};
    //   this.metaService.detail(id).subscribe(data => {
    //     this.selectedArticle = data;
    //   });
    // }
    getAuthor() {
        this.authorServices.get('').subscribe(data => {
            this.authorList = data['data'];
        });
    }

    getArticleCategory(params: any = {}) {
        this.blogList = [];
        this.blogService.get({'page': -1, 'status': 'ACTIVE'}).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.blogList = data['data'];
            } else {
                this.blogList = [];
            }
        });
    }

    submit() {
        this.submitted = true;
        if (!this.addMetaForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                // 'author_id': (this.addMetaForm.value && this.addMetaForm.value.author_id) ? this.addMetaForm.value.author_id : '',
                'title': (this.addMetaForm.value && this.addMetaForm.value.title) ? this.addMetaForm.value.title : '',
                'meta_keyword': (this.addMetaForm.value && this.addMetaForm.value.meta_keyword) ? this.addMetaForm.value.meta_keyword : '',
                'blog_id': (this.addMetaForm.value && this.addMetaForm.value.blog_id) ? this.addMetaForm.value.blog_id : '',
                'meta_description': (this.addMetaForm.value && this.addMetaForm.value.meta_description) ? this.addMetaForm.value.meta_description : '',
                // 'meta_og_type': (this.addMetaForm.value && this.addMetaForm.value.meta_og_type) ? this.addMetaForm.value.meta_og_type : '',
                // 'meta_og_title': (this.addMetaForm.value && this.addMetaForm.value.meta_og_title) ? this.addMetaForm.value.meta_og_title : '',
                // 'meta_og_image': (this.addMetaForm.value && this.addMetaForm.value.meta_og_image) ? this.addMetaForm.value.meta_og_image : '',
                // 'meta_og_url': (this.addMetaForm.value && this.addMetaForm.value.meta_og_url) ? this.addMetaForm.value.meta_og_url : '',
                // 'meta_og_description': (this.addMetaForm.value && this.addMetaForm.value.meta_og_description) ? this.addMetaForm.value.meta_og_description : '',
                // 'meta_twitter_card': (this.addMetaForm.value && this.addMetaForm.value.meta_twitter_card) ? this.addMetaForm.value.meta_twitter_card : '',
                // 'meta_twitter_description': (this.addMetaForm.value && this.addMetaForm.value.meta_twitter_description) ? this.addMetaForm.value.meta_twitter_description : '',
                // 'meta_twitter_image': (this.addMetaForm.value && this.addMetaForm.value.meta_twitter_image) ? this.addMetaForm.value.meta_twitter_image : '',
                // 'meta_twitter_url': (this.addMetaForm.value && this.addMetaForm.value.meta_twitter_url) ? this.addMetaForm.value.meta_twitter_url : '',
                // 'meta_twitter_title': (this.addMetaForm.value && this.addMetaForm.value.meta_twitter_title) ? this.addMetaForm.value.meta_twitter_title : '',
            };
            this.submitting = true;
            this.metaService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addMetaForm.reset();
                    this.addMetaForm.patchValue({
                        'title': (this.data && this.data['title']) ? this.data['title'] : '',
                        'blog_id': (this.data && this.data['blog_id']) ? this.data['blog_id'] : '',
                        'meta_description': (this.data && this.data['meta_description']) ? this.data['meta_description'] : '',
                        'meta_keyword': (this.data && this.data['meta_keyword']) ? this.data['meta_keyword'] : '',
                        // 'meta_og_url': (this.data && this.data['meta_og_url']) ? this.data['meta_og_url'] : '',
                        // 'meta_og_title': (this.data && this.data['meta_og_title']) ? this.data['meta_og_title'] : '',
                        // 'meta_og_type': (this.data && this.data['meta_og_type']) ? this.data['meta_og_type'] : '',
                        // 'meta_og_image': (this.data && this.data['meta_og_image']) ? this.data['meta_og_image'] : '',
                        // 'meta_og_description': (this.data && this.data['meta_og_description']) ? this.data['meta_og_description'] : '',
                        // 'meta_twitter_card': (this.data && this.data['meta_twitter_card']) ? this.data['meta_twitter_card'] : '',
                        // 'meta_twitter_title': (this.data && this.data['meta_twitter_title']) ? this.data['meta_twitter_title'] : '',
                        // 'meta_twitter_description': (this.data && this.data['meta_twitter_description']) ? this.data['meta_twitter_description'] : '',
                        // 'meta_twitter_url': (this.data && this.data['meta_twitter_url']) ? this.data['meta_twitter_url'] : '',
                        // 'meta_twitter_image': (this.data && this.data['meta_twitter_image']) ? this.data['meta_twitter_image'] : '',
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].university_name && error['error']['errors'].title.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].title[0]);
                        return;
                    }
                    // if (error['error']['errors'].about_course && error['error']['errors'].short_description.length > 0) {
                    //   this.alertService.showErrors(error['error']['errors'].short_description[0]);
                    //   return;
                    // }
                    // if (error['error']['errors'].about_course && error['error']['errors'].article_category_id.length > 0) {
                    //   this.alertService.showErrors(error['error']['errors'].article_category_id[0]);
                    //   return;
                    // }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addMetaForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'title': (this.addMetaForm.value && this.addMetaForm.value.title) ? this.addMetaForm.value.title : '',
                'meta_keyword': (this.addMetaForm.value && this.addMetaForm.value.meta_keyword) ? this.addMetaForm.value.meta_keyword : '',
                'blog_id': (this.addMetaForm.value && this.addMetaForm.value.blog_id) ? this.addMetaForm.value.blog_id : '',
                'meta_description': (this.addMetaForm.value && this.addMetaForm.value.meta_description) ? this.addMetaForm.value.meta_description : '',
                // 'meta_og_type': (this.addMetaForm.value && this.addMetaForm.value.meta_og_type) ? this.addMetaForm.value.meta_og_type : '',
                // 'meta_og_title': (this.addMetaForm.value && this.addMetaForm.value.meta_og_title) ? this.addMetaForm.value.meta_og_title : '',
                // 'meta_og_image': (this.addMetaForm.value && this.addMetaForm.value.meta_og_image) ? this.addMetaForm.value.meta_og_image : '',
                // 'meta_og_url': (this.addMetaForm.value && this.addMetaForm.value.meta_og_url) ? this.addMetaForm.value.meta_og_url : '',
                // 'meta_og_description': (this.addMetaForm.value && this.addMetaForm.value.meta_og_description) ? this.addMetaForm.value.meta_og_description : '',
                // 'meta_twitter_card': (this.addMetaForm.value && this.addMetaForm.value.meta_twitter_card) ? this.addMetaForm.value.meta_twitter_card : '',
                // 'meta_twitter_description': (this.addMetaForm.value && this.addMetaForm.value.meta_twitter_description) ? this.addMetaForm.value.meta_twitter_description : '',
                // 'meta_twitter_image': (this.addMetaForm.value && this.addMetaForm.value.meta_twitter_image) ? this.addMetaForm.value.meta_twitter_image : '',
                // 'meta_twitter_url': (this.addMetaForm.value && this.addMetaForm.value.meta_twitter_url) ? this.addMetaForm.value.meta_twitter_url : '',
                // 'meta_twitter_title': (this.addMetaForm.value && this.addMetaForm.value.meta_twitter_title) ? this.addMetaForm.value.meta_twitter_title : '',
            };
            this.submitting = true;
            this.metaService.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
                this.addMetaForm.reset();
                this.addMetaForm.patchValue({
                    // 'article_category_id': '',
                    'title': '',
                    // 'short_description': '',
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    // if (error['error']['errors'].full_course_name && error['error']['errors'].article_category_id.length > 0) {
                    //   this.alertService.showErrors(error['error']['errors'].article_category_id[0]);
                    //   return;
                    // }
                    if (error['error']['errors'].title && error['error']['errors'].title.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].title[0]);
                        return;
                    }
                    // if (error['error']['errors'].about_course && error['error']['errors'].short_description.length > 0) {
                    //   this.alertService.showErrors(error['error']['errors'].short_description[0]);
                    //   return;
                    // }
                }
            });
        }
    }

    // onFileChange(e, type, index?) {
    //   this.onFileChanged = e;
    //   this.metaService.fileUpload({'type': type, 'fileType': 'NORMAL'}, this.onFileChanged).subscribe(data => {
    //     if (type === 'ARTICLE_COVER') {
    //       this.fileCourseCover = data['data'];
    //     }
    //     this.alertService.showSuccess({title: 'Success', message: 'Uploaded Successfully'});
    //   }, error => {
    //     this.alertService.showErrors('Something went wrong, please try again');
    //   });
    // }

    openInsightCover() {
        window.open(this.fileCourseCover['url'], '_blank');
    }
}
