import {Injectable} from '@angular/core';

@Injectable()
export class AppConstants {
    static markingSchemes = [
        {'name': 'Percentage', 'value': 'PERCENTAGE'},
        {'name': 'CGPA', 'value': 'CGPA'}
    ];

    static boardUniversity = [
        {'name': 'CBSE', 'value': 'CBSE'},
        {'name': 'ICSE', 'value': 'ICSE'},
        {'name': 'IB', 'value': 'IB'},
        {'name': 'State Board', 'value': 'STATE_BOARD'}
    ];

    static AllAwardsType: any = [
        {
           'name': 'Majestic',
            'value': 'majestic'
        },
        {
            'name': 'Cel',
            'value': 'cel'
        }
    ];

    static AllWebpages: any = [
        {
            'name': 'About Us',
            'value': 'ABOUT_US'
        },
        {
            'name': 'Videos',
            'value': 'VIDEOS'
        },
        {
            'name': 'Articles',
            'value': 'ARTICLES'
        },
        {
            'name': 'Magazines',
            'value': 'MAGAZINES'
        },
        {
            'name': 'News Papers',
            'value': 'NEWS_PAPERS'
        },
        {
            'name': 'Blogs',
            'value': 'BLOGS'
        },
        {
            'name': 'Contact Us',
            'value': 'CONTACT_US'
        }
    ];
}
