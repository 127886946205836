import {Component, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {ArticleService} from '../../../shared/services/article.service';
import {Subject} from 'rxjs';
import {BlogService} from '../../../shared/services/blog.service';

@Component({
    selector: 'app-article-info',
    templateUrl: './article-info.component.html',
    styleUrls: ['./article-info.component.scss']
})
export class ArticleInfoComponent implements OnInit {
    public data: any;
    public toggleMenu = false;
    private _unsubscribeAll: Subject<any>;
    submitting: any = false;
    submitted: any = false;
    contentData: any;
    public articleId: any;

    constructor(private sanitizer: DomSanitizer,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private articleServices: ArticleService) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.articleId = this.activatedRoute.snapshot.params['id'];
        this.refresh();
    }

    refresh() {
        this.articleServices.detail(this.articleId).subscribe(data => {
            if (data) {
                this.data = data;
                if (this.data && this.data['content']) {
                    this.contentData = this.data['content'];
                }
            } else {
                this.router.navigateByUrl('articles');
            }
        }, error => {
            this.router.navigateByUrl('articles');
        });
    }

    submit() {
        const params = {
            'content': this.contentData ? this.contentData : ''
        };
        this.articleServices.update_content(this.articleId, params).subscribe(data => {
            if (data) {
                this.submitting = false;
                this.submitted = false;
            }
        }, error => {
            this.submitting = false;
            this.submitted = false;
        });
    }
}
