import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BreakingNewsRoutingModule} from './breaking-news-routing.module';
import {BreakingNewsComponent} from './breaking-news.component';
import {NgbPaginationModule} from "@ng-bootstrap/ng-bootstrap";
import {CoreSidebarModule} from "../../../@core/components";
import {MagazineModule} from "../magazine/magazine.module";
import {ReactiveFormsModule} from "@angular/forms";


@NgModule({
    declarations: [BreakingNewsComponent],
    imports: [
        CommonModule,
        BreakingNewsRoutingModule,
        NgbPaginationModule,
        CoreSidebarModule,
        MagazineModule,
        ReactiveFormsModule
    ]
})
export class BreakingNewsModule {
}
