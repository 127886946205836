import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CoreSidebarService} from '../../../../@core/components/core-sidebar/core-sidebar.service';
import {AlertService} from '../../../shared/services/alert.service';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../../../shared/services/global.service';
import {NewsPaperService} from '../../../shared/services/news-paper.service';

@Component({
    selector: 'app-add-news',
    templateUrl: './add-news.component.html',
    styleUrls: ['./add-news.component.scss']
})
export class AddNewsComponent implements OnInit {
    addNewsPaperForm: FormGroup;
    id: any;
    submitted: any = false;
    submitting: any = false;
    onFileChanged: any;
    fileCourseCover: any;
    @Output() valueSubmitted = new EventEmitter<any>();
    @Input() data: any;
    selectedNewsPaper: any;
    authorList: any;
    articleCategories: any = [];
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };

    constructor(private _coreSidebarService: CoreSidebarService,
                private fb: FormBuilder,
                private alertService: AlertService,
                public http: HttpClient,
                public globalService: GlobalService,
                private newsPaperService: NewsPaperService) {
    }

    ngOnInit(): void {

    }

    ngOnChanges(): void {
        this.refresh();
    }

    refresh() {
        this.addNewsPaperForm = this.fb.group({
            'title': ['', (!this.id) ? Validators.required : ''],
        });
        if (this.data && this.data['id']) {
            this.patchForm();
        }
    }

    toggleSidebar(): void {
        this._coreSidebarService.getSidebarRegistry('add-news').toggleOpen();
    }

    patchForm() {
        // if (this.data) {
        //     this.getMagazine(this.data['id']);
        // }
        this.addNewsPaperForm.patchValue({
            'title': (this.data && this.data['title']) ? this.data['title'] : '',
        });
    }

    submit() {
        this.submitted = true;
        if (!this.addNewsPaperForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'title': (this.addNewsPaperForm.value && this.addNewsPaperForm.value.title) ? this.addNewsPaperForm.value.title : '',
            };
            this.submitting = true;
            this.newsPaperService.create(params).subscribe(data => {
                if (data) {
                    this.submitting = false;
                    this.submitted = false;
                    this.valueSubmitted.emit(true);
                    this.addNewsPaperForm.reset();
                    this.addNewsPaperForm.patchValue({
                        'title': '',
                    });
                }
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].title && error['error']['errors'].title.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].title[0]);
                        return;
                    }
                }
            });
        }
    }

    update() {
        this.submitted = true;
        if (!this.addNewsPaperForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'title': (this.addNewsPaperForm.value && this.addNewsPaperForm.value.title) ? this.addNewsPaperForm.value.title : '',
            };
            this.submitting = true;
            this.newsPaperService.update(this.data['id'], params).subscribe(data => {
                this.submitting = false;
                this.submitted = false;
                this.valueSubmitted.emit(true);
                this.addNewsPaperForm.reset();
                this.addNewsPaperForm.patchValue({
                    'title': '',
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].title && error['error']['errors'].title.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].title[0]);
                        return;
                    }
                }
            });
        }
    }

    openInsightCover() {
        window.open(this.fileCourseCover['url'], '_blank');
    }
}
