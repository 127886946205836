import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {MetaComponent} from './meta.component';
import {AddMetaComponent} from './add-meta/add-meta.component';

const routes: Routes = [
  {
    path: 'meta',
    component: MetaComponent,
    data: {animation : 'metas'}
  },
  {
    path: 'meta/add',
    component: AddMetaComponent,
    data: {animation : 'meta/add'}
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MetaRoutingModule { }
