<div class="content-wrapper">
    <div class="content-body">
        <section id="articles">
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <h2 class="mt-5px">News Categories</h2>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <button (click)="showSearch = !showSearch"
                            class="btn btn-primary pull-right color-white color-white users-btn">
                        <i aria-hidden="true" class="fa fa-search mr-8px"></i>&nbsp;Search
                    </button>
                    <button (click)="add()"
                            class="btn btn-primary pull-right color-white color-white users-btn mr-2 cursor-pointer">
                        <i aria-hidden="true" class="fa fa-plus-circle mr-8px"></i>&nbsp;Add News Category
                    </button>
                </div>
            </div>
            <div *ngIf="showSearch" class="row search-card mt-3">
                <div class="col-12">
                    <form [formGroup]="searchForm" class="form mt-2 mb--8px" novalidate>
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-6 col-xs-12">
                                <fieldset class="form-group">
                                    <input class="form-control" formControlName="name" placeholder="Name" type="text">
                                </fieldset>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-2 col-xs-12 text-center">
                                <button (click)="search()"
                                        [disabled]="submitting"
                                        class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn">
                                    <i *ngIf="submitting" class="fas fa-spinner fa-pulse mr-1"></i>Search
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="row d-none d-md-block mt-3">
                <div class="col-12">
                    <div class="card">
                        <div class="container-fluid">
                            <div class="row d-none d-md-block overflow-x-scroll overflow-table">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <table class="table table-list">
                                        <thead>
                                        <tr>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">S.No</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Name</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Alias</h6>
                                            </th>
                                            <th class="text-center"><h6 class="mt-1 mb-1 font-weight-bold">Banner</h6>
                                            </th>
                                            <th class="text-center">
                                                <h6 class="mt-1 mb-1 font-weight-bold">Action</h6>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody *ngIf="newsCategories && newsCategories.length > 0; else noDataToShowWeb">
                                        <tr *ngFor="let category of newsCategories; let i = index">
                                            <td class="text-center">
                                                <h6 class="mt-1">{{(pagination.page - 1) * (pagination.perpage ?
                                                    pagination.perpage : 0) + i + 1}}
                                                    .</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{category?.name}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <h6 class="mt-1">{{category?.alias}}</h6>
                                            </td>
                                            <td class="text-center">
                                                <div *ngIf="category && category['news_category_banner']"
                                                     class="btn-group"
                                                     role="group">
                                                    <button (click)="bannerUpload.click()" class="btn btn-primary"
                                                            title="Upload Banner"
                                                            type="button">
                                                        <i class="fas fa-cloud-upload-alt"></i>
                                                    </button>
                                                    <button (click)="openInsightBanner(category)"
                                                            class="btn btn-primary" title="View Banner"
                                                            type="button">
                                                        <i class="fas fa-eye"></i>
                                                    </button>
                                                </div>
                                                <button (click)="bannerUpload.click()"
                                                        *ngIf="category && !category['news_category_banner']"
                                                        class="btn btn-primary color-white"
                                                        title="Upload">
                                                    <i class="fas fa-cloud-upload-alt"></i>
                                                </button>
                                                <input #bannerUpload
                                                       (change)="onFileChange($event, 'NEWS_CATEGORY_BANNER', category)"
                                                       class="form-control-file display-hidden"
                                                       type="file">
                                            </td>
                                            <td class="text-center">
                                                <i (click)="edit(category)"
                                                   aria-hidden="true"
                                                   class="fas fa-edit f_20 text-info cursor-pointer font-weight-light mt-1"
                                                   title="Edit"></i>
                                                <i (click)="updateStatus(category, 'ACTIVE')"
                                                   *ngIf="category && category['status'] === 'IN_ACTIVE'"
                                                   class="fas fa-check-circle f_20 ml-5px text-success cursor-pointer"
                                                   title="Active"></i>
                                                <i (click)="updateStatus(category, 'IN_ACTIVE')"
                                                   *ngIf="category && category['status'] === 'ACTIVE'"
                                                   class="fas fa-times-circle f_20 ml-5px text-danger cursor-pointer"
                                                   title="In Active"></i>
                                                <i (click)="delete(category.id)"
                                                   aria-hidden="true"
                                                   class="fas fa-trash f_20 ml-5px text-danger cursor-pointer mt-1"
                                                   title="Delete"></i>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataToShowWeb>
                                            <tbody>
                                            <tr>
                                                <td class="text-center" colspan="5">
                                                    <h6>No data to show</h6>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="newsCategories && newsCategories.length > 0; else noDataToShowMobile"
                 class="row mt-3 d-block d-md-none">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div *ngFor="let category of newsCategories; let i = index" class="card">
                        <div class="card-header bottom-shadow-light pb-18px">
                            <div class="row width-100-per">
                                <div class="col-6">
                                    <h4 class="card-title text-uppercase card-ellipsis">{{(pagination.page - 1) *
                                    (pagination.perpage ? pagination.perpage : 0) + i + 1}}
                                        . {{category?.name}}</h4>
                                </div>
                                <div class="col-6">
                                    <!-- <i aria-hidden="true"
                                        class="fas fa-lock f_20 ml-1 text-danger cursor-pointer pull-right"
                                        title="In Active Executive" *ngIf="category?.status === 'ACTIVE'"
                                        (click)="updateStatus('INACTIVE', category)"></i>
                                     <i aria-hidden="true"
                                        class="fas fa-lock-open ml-1 f_20 text-success cursor-pointer pull-right"
                                        title="Active Executive" *ngIf="category?.status === 'INACTIVE'"
                                        (click)="updateStatus('ACTIVE', category)"></i>-->
                                    <i (click)="edit(category)"
                                       aria-hidden="true"
                                       class="fas fa-edit f_20 text-info cursor-pointer font-weight-light pull-right"
                                       title="Edit"></i>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row p-2">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-1">
                                    Name - {{category?.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #noDataToShowMobile>
                <div class="row mt-3 d-block d-md-none">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card text-center">
                            <h5 class="p-5">No data to show</h5>
                        </div>
                    </div>
                </div>
            </ng-template>
            <div class="row pagination-table">
                <ngb-pagination (pageChange)="onPageChange($event)" [(page)]="pagination.page" [boundaryLinks]="true"
                                [collectionSize]="pagination.total" [maxSize]="5" [pageSize]="pagination.perpage">
                </ngb-pagination>
            </div>
        </section>
    </div>
    <core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="app-add-news-category"
                  overlayClass="modal-backdrop">
        <app-add-news-category (valueSubmitted)="getData($event)" [data]="data"></app-add-news-category>
    </core-sidebar>
</div>
