import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {NewsPapersComponent} from './news-papers.component';
// import {AddNewsPapersComponent} from './add-news-papers/add-news-papers.component';
import {AddNewsComponent} from './add-news/add-news.component';

const routes: Routes = [
  {
    path: 'news-papers',
    component: NewsPapersComponent,
    data: {animation : 'news-papers'}
  },
  {
    path: 'news/add',
    component: AddNewsComponent,
    data: {animation : 'add/news'}
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NewsPapersRoutingModule { }
