import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ArticleCategoriesRoutingModule} from './article-categories-routing.module';
import {AddArticleCategoryComponent} from './add-article-category/add-article-category.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxTagsInputModule} from 'ngx-tags-input';


@NgModule({
    declarations: [AddArticleCategoryComponent],
    imports: [
        CommonModule,
        ArticleCategoriesRoutingModule,
        ReactiveFormsModule,
        NgxTagsInputModule,
        FormsModule,
    ],
    exports: [
        AddArticleCategoryComponent
    ]
})
export class ArticleCategoriesModule {
}
