import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NewsCategoriesComponent} from './news-categories.component';
import {AddNewsCategoryComponent} from './add-news-category/add-news-category.component';

const routes: Routes = [
    {
        path: 'news-categories',
        component: NewsCategoriesComponent,
        data: {animation: 'news-categories'}
    },
    {
        path: 'news-category/add',
        component: AddNewsCategoryComponent,
        data: {animation: 'news-category/add'}
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class NewsCategoriesRoutingModule {
}
