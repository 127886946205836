import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WebpageBannerRoutingModule} from './webpage-banner-routing.module';
import {WebpageBannerComponent} from './webpage-banner.component';
import {AddWebpageComponent} from './add-webpage/add-webpage.component';
import {ReactiveFormsModule} from '@angular/forms';
import {CoreSidebarModule} from "../../../@core/components";
import {NewsCategoriesModule} from "../news-categories/news-categories.module";

@NgModule({
    declarations: [WebpageBannerComponent, AddWebpageComponent],
    imports: [
        CommonModule,
        WebpageBannerRoutingModule,
        ReactiveFormsModule,
        CoreSidebarModule,
        NewsCategoriesModule
    ],
    exports: [AddWebpageComponent]
})
export class WebpageBannerModule {
}
